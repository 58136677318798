import React,{useRef,useState} from 'react'
import IdleTimer from 'react-idle-timer'
import {Modal,Button} from 'antd'
import axios from 'axios'
import apiURL from './env'
import { Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {getAuthentication} from './Actions/loginCheck'
const IdleTimerContainer=(props)=>{
    const idleTimerRef=useRef(null)
    const sessionTimeoutRef=useRef(null)
    const [isModalVisible, setstate] = useState(false)
    const [redirectPath, setRedirectPath] = useState("")
    const onIdle=()=>{
        console.log("shjdgsjhdg")
        setstate(true)
        // sessionTimeoutRef.current=setTimeout(() => {
        //     handleOk()
        // }, 15000);
    }
    const handleOk=()=>{
        axios({
            method: 'POST',
            url: apiURL + 'leads/logout'
        }).then((res) => {
            localStorage.clear()
            props.getAuthentication()
            setRedirectPath("/")
        })
        setstate(false)

    }
    const handleCancel=()=>{
        // clearTimeout(sessionTimeoutRef.current)
        setstate(false)
    }
    return(
        <div>
            <Modal 
                className="idle-time-modal"
                title="You've been idle for a while!"
                 visible={isModalVisible} 
                 footer={null}
                closable={false}
            >
                <label className="d-block mb-3">You will be logged out soon</label>
                <Button onClick={handleOk} className="mr-3 logout-btn">Log me out</Button>
                <Button onClick={handleCancel} className="keep-signin-btn">Keep me signed in</Button>
            </Modal>
            <IdleTimer ref={idleTimerRef} timeout={300*1000} onIdle={onIdle}></IdleTimer>
            {redirectPath!=""&&(
                <Redirect to={redirectPath}/>
            )}
        </div>
    )
}
const mapStateToProps=(state)=>{
    return {}
}
const mapDispatchToProps=(dispatch)=>{
    return bindActionCreators({ getAuthentication: getAuthentication},dispatch)
}
export default connect(mapStateToProps,mapDispatchToProps)(IdleTimerContainer)