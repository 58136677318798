import React,{useState} from 'react'
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import { LoadingOutlined } from '@ant-design/icons'
import apiURL from '../../env'

import MainHeader from '../../Header/main'
const ForgotPassword = (props) => {
    const [forgotState, setForgotState] = useState(false)
    const [forgotText, setForgotText] = useState("SUBMIT")
    const { register, handleSubmit, errors } = useForm();
    const onSubmit = data => {
        if (data) {
            setForgotState(true)
        axios({
            method: "GET",
            url: apiURL+'leads/forgotPassword',
            params: {
                email: data.email,
                origin: window.location.origin
            }
        }).then((res) => {
        setForgotText(res.data.status)
            setTimeout(() => {
                setForgotText("Submit")
                setForgotState(false)
            }, 3000)
        }).catch((err) => {
            setForgotState(false)
            toast.error("Email not found", {
                position: "top-right"
            });
        })
    }}
    return (
        <React.Fragment>
        <MainHeader/>
            <div className="content-wrap login-wrap overlay" style={{ backgroundImage: "url('https://daaistore.s3-ap-southeast-1.amazonaws.com/bg-login.jpg')" }}>
            <div className="container">
                <div className="login-form">
                    <h2>Forgot Password</h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <input className="form-control" autoFocus name="email" placeholder="Email" ref={
                                register({
                                    pattern: {
                                        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: 'Invalid E-mail address' // JS only: <p>error message</p> TS only support string
                                    },
                                    required: 'Email is required'
                                })
                            }
                            />
                            {errors.email && <span className="login-error-msg">{errors.email.message}</span>}
                        </div>
                        <button className="login-submit-btn btn w-100" type="submit">{forgotText}{forgotState&&<LoadingOutlined/>}</button>
                        <span className="signup-msg d-block text-center"><Link to="/login" style={{textDecoration:'underline'}}>Back To Login</Link></span>
                    </form>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}
export default ForgotPassword