import React, { Component } from 'react'
/*------------------Packages---------------------------*/
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom'
import { Button, Input, Tag } from 'antd'
import axios from 'axios'
/*------------------Packages----------------------------*/
/*------------------Actions-----------------------------*/
import { getQuestionById } from '../../Actions/getQuestionById'
import { saveSurvey } from '../../Actions/saveSurvey'
import { getAuthentication } from '../../Actions/loginCheck'
import { getUserSectionData } from '../../Actions/getUserData'
import { questStartRequest } from '../../Actions/questionLoader';
/*------------------Actions-----------------------------*/
/*------------------Components-----------------------------*/
import MainHeader from '../../Header/main';
import SectionHeader from '../../Header/sectionHeader';
import fullGlossary from '../../glossary'
/*------------------Components-----------------------------*/
import KeyboardEventHandler from 'react-keyboard-event-handler';
import apiURL from '../../env'
const KEYS = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "ENTER", "TAB", "SPACE"]
class Organization extends Component {
    constructor(props) {
        super(props)
        this.lastFetchId = 0;
        let orderValue = this.props.auth.surveyDetails.order.filter(record => (record._id == "602cbde7660a890265198806"))
        this.state = {
            orderArray: orderValue.length > 0 ? orderValue[0].questionOrder : [],
            indexValue: 0,
            percent: 0,
            ansArr: [],
            answerIndex: 0,
            clickedButton: "",
            progressValue: 0,
            selectedImage: "https://daaistore.s3-ap-southeast-1.amazonaws.com/sketch-organization.svg",
            minValidMsg: "",
            isRedirect: false,
            redirectPath: "",
            navigate: false,
            path: "",
            pageIndex: 1,
            customPhone: '',
            customZip: ''
        }
    }
    componentDidMount() {
        this.props.questStartRequest()
        this.getData()
    }
    getData() {
        setTimeout(() => {
            let catData = this.props.userSectionData.data.filter(element => element.categoryId == "602cbde7660a890265198806")
            if (catData.length > 0 && catData[0].lastAnswerdQuestionId != "" && Object.values(this.props.fullQuestionData.data).length > 0) {
                let order = this.props.fullQuestionData.data[catData[0].lastAnswerdQuestionId].order
                let orderVal = this.props.auth.surveyDetails.order.filter(record => (record._id == "602cbde7660a890265198806"))
                this.setState({ indexValue: order - 1, pageIndex: orderVal[0].questionOrder.length })
            } else {
                this.setState({ indexValue: 0, pageIndex: 1 })
            }
        }, 5000)
    }
    removeOptions() {
        if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice == Object.values(this.props.fullQuestionData.data)[this.state.indexValue].maxChoice) {
            if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length > Object.values(this.props.fullQuestionData.data)[this.state.indexValue].maxChoice) {
                Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.splice(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length - 2, 1)
                Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.splice(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.length - 2, 1)
                this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                this.state.ansArr.splice(this.state.ansArr.length - 2, 1)
                this.setState({ ansArr: this.state.ansArr })
                if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length == Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice) {
                    this.checkValidationMsg()
                }
            }
            if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length == Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice) {
                this.checkValidationMsg()
            }
        }
    }
    componentWillMount() {
        setTimeout(() => {
            window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
            this.props.getAuthentication()
            this.props.getQuestionById("602cbde7660a890265198806", "")
            let catData = this.props.userSectionData.data.length && this.props.userSectionData.data.filter(element => element.categoryId == "602cbde7660a890265198806")
            if (catData[0].isCompleted) {
                this.setState({ isRedirect: true, redirectPath: "/organization-result" })
            } else {
                this.setState({ isRedirect: true, redirectPath: "/organization" })
            }
        }, 3000)
    }
    checkValidationMsg() {
        if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice == Object.values(this.props.fullQuestionData.data)[this.state.indexValue].maxChoice) {
            if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length < Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice) {
                this.setState({ minValidMsg: "Please choose " + Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice + " options" })
            } else {
                this.setState({ minValidMsg: "" })
            }
        }
    }
    backArrowFunction() {
        if (this.state.indexValue >= 1) {
            this.setState({ pageIndex: this.state.pageIndex - 1 })
            let indexStatus = this.state.orderArray.indexOf(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].order)
            if (indexStatus > -1) {
                let arr = this.state.orderArray.slice(0, indexStatus)
                let indexOrder = arr.pop()
                this.setState({ indexValue: indexOrder - 1, clickedButton: "back", minValidMsg: "" }, this.checkIfSelected)
            } else {
                let indexOrder = this.state.orderArray.pop()
                this.setState({ indexValue: indexOrder - 1, clickedButton: "back", minValidMsg: "" }, this.checkIfSelected)
            }
            this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY");
        }
        // if (document.querySelector('.highlight-answer'))
        //     document.querySelector('.highlight-answer').className = "quest-answer-block"
        this.callImage()
    }
    checkIfSelected() {
        if (this.state.indexValue <= Object.values(this.props.fullQuestionData.data).length - 1) {
            if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].questionType == "Radio") {
                if (document.querySelector('.selected-answer')) {
                    Object.values(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answers).forEach((data, index) => {
                        if (data.answerId == document.querySelector('.selected-answer input').id)
                            this.setState({ answerIndex: index + 1 })
                    })
                } else {
                    this.setState({ answerIndex: 0 })
                }
            } else if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].questionType == "Select") {
                if (document.querySelector('.selected-answer')) {
                    Object.values(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answers).forEach((data, index) => {
                        if (data.answerId == document.querySelector('.selected-answer input').id)
                            this.setState({ answerIndex: index + 1 })
                    })
                } else {
                    this.setState({ answerIndex: 0 })
                }
            }
        }
    }
    callImage() {
        var imageArray = ["https://daaistore.s3-ap-southeast-1.amazonaws.com/sketch-organization.svg", "https://daaistore.s3-ap-southeast-1.amazonaws.com/sketch-people.svg", "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/start-section-image2.png", "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/start-section-image3.png", "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/start-section-image4.png"]
        var num = Math.floor(Math.random() * imageArray.length);
        this.setState({ selectedImage: imageArray[num] })
    }
    changeMessage(value, data) {
        if (value != "")
            this.setState({ specificationDesStatus: true })
        else
            this.setState({ specificationDesStatus: false })
        Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer[0] = { "answerId": data.answerId, "answer": data.answer, "specify": true, "specifyDescription": value }
        this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTIONS")
    }
    nextClicked() {
        this.callImage()
        if (this.state.indexValue <= Object.values(this.props.fullQuestionData.data).length) {
            this.setState({ clickedButton: "next" })
            var stateArr = [];
            if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.length > 0) {
                stateArr = Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer
            } else {
                stateArr = this.state.ansArr
            }
            if (document.querySelector('.highlight-answer')) {
                document.querySelector('.highlight-answer').className = "quest-answer-block"
            }
            const promise1 = axios({
                method: 'POST',
                url: apiURL + 'survey/addSurvey?access_token=' + localStorage.token,
                data: {
                    "sponsorId": this.props.auth.authData.sponsorId,
                    "couponId": this.props.auth.authData.couponId,
                    "sectionId": "602cbde7660a890265198806",
                    "questionId": Object.values(this.props.fullQuestionData.data)[this.state.indexValue].questionId,
                    "questionOrder": Object.values(this.props.fullQuestionData.data)[this.state.indexValue].order,
                    "answers": stateArr,
                    "questionIndex": this.state.pageIndex,
                    "custom": Object.values(this.props.fullQuestionData.data)[this.state.indexValue].custom,
                    "isLastQuestion": this.state.indexValue + 1 == Object.values(this.props.fullQuestionData.data).length ? true : false
                }
            })
            const promise2 = axios({
                method: 'PATCH',
                url: apiURL + 'surveyUpdates/updateOnSurvey?access_token=' + localStorage.token,
                data: {
                    categoryId: "602cbde7660a890265198806",
                    lastAnswerdQuestionId: Object.values(this.props.fullQuestionData.data)[this.state.indexValue].questionId,
                    isCompleted: this.state.indexValue + 1 == Object.values(this.props.fullQuestionData.data).length ? true : false
                }
            })
            var that = this;
            Promise.all([promise1, promise2]).then(values => {
                let orderValues = values[0].data.order.filter(record => (record._id == "602cbde7660a890265198806"))
                that.setState({ orderArray: orderValues[0].questionOrder })
                if (values[0].data.answers) {
                    that.setState({ ansArr: [] })
                    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
                    if (that.state.indexValue + 1 == Object.values(that.props.fullQuestionData.data).length) {
                        this.props.getAuthentication()
                        this.setState({ isRedirect: true, redirectPath: "/organization-result" })
                    } else {
                        that.setState({ pageIndex: that.state.pageIndex + 1 })
                        try {
                            Object.values(that.props.fullQuestionData.data).forEach((rec, index) => {
                                if ((Object.values(that.props.fullQuestionData.data)[that.state.indexValue].questionId != rec.questionId) && (rec.order > Object.values(that.props.fullQuestionData.data)[that.state.indexValue].order)) {
                                    if ((rec.showAnswer.length == 0) && (rec.hideAnswer.length == 0)) {
                                        that.setState({ indexValue: index, progressValue: that.state.indexValue + 1, }, that.checkIfSelected)
                                        throw Error("Time to end the loop," + index);
                                    } else {
                                        if (rec.showAnswer.length == 0 && rec.hideAnswer.length != 0) {
                                            const hideAnswerLength = rec.hideAnswer.filter(value => values[0].data.answers.includes(value));
                                            if (hideAnswerLength.length == 0) {
                                                that.setState({ indexValue: rec.order - 1, progressValue: that.state.indexValue + 1, }, that.checkIfSelected)
                                                throw Error("Time to end the loop," + index);
                                            }
                                        } else if (rec.showAnswer.length != 0 && rec.hideAnswer.length == 0) {
                                            const showAnswerLength = rec.showAnswer.filter(value => values[0].data.answers.includes(value));
                                            if (showAnswerLength.length > 0) {
                                                that.setState({ indexValue: index, progressValue: that.state.indexValue + 1, }, that.checkIfSelected)
                                                throw Error("Time to end the loop," + index);
                                            }
                                        }
                                    }
                                }
                            })
                        }
                        catch (e) {
                            // console.log(e, "Loop has ended");
                        }
                    }

                }
            })
        }
    }
    getSpecifyMessage(arrayData) {
        let message = ""
        Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.forEach((rec, ind) => {
            if (rec.specify) {
                message =
                    <Input type="text" className="form-control" value={rec.specifyDescription} onChange={(e) => this.changeMessage(e.target.value, rec)} />
            }
        })
        return message
    }
    navigateAnswer() {
        if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].questionType != "SelectTable") {
            if (document.querySelector(".highlight-answer")) {
                var currentElementClass = document.querySelector('.highlight-answer').className;
                if (currentElementClass != 'quest-answer-block animated pulse selected-answer highlight-answer')
                    document.querySelector(".quest-answer-block.highlight-answer").className = 'quest-answer-block';
            }
            var navigateElementClass = document.querySelector(".quest-answer-block:nth-child(" + this.state.answerIndex + ")").className;
            if (navigateElementClass != 'quest-answer-block animated pulse selected-answer')
                document.querySelector(".quest-answer-block:nth-child(" + this.state.answerIndex + ")").className += ' highlight-answer';
        }
    }
    //click on checkbox options
    setCheckboxOptions(e, data, index) {
        if (e.target.checked) {
            this.setState({ answerIndex: index + 1 })
            Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.forEach((dbRec, dbInd) => {
                if (dbRec.specify) {
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.splice(dbInd, 1)
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.splice(dbInd, 1)
                    this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                    this.state.ansArr.splice(dbInd, 1)
                    this.setState({ ansArr: this.state.ansArr })
                }
                if (dbRec.removeOther) {
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.splice(dbInd, 1)
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.splice(dbInd, 1)
                    this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                    this.state.ansArr.splice(dbInd, 1)
                    this.setState({ ansArr: this.state.ansArr })
                }
            })
            if (!data.specify) {
                if (data.removeOther) {
                    let answerSet = {
                        "answer": data.answer, "answerId": data.answerId, "answerIndex": index + 1,
                        "removeOther": data.removeOther,
                    }
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray = []
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer = []
                    this.state.ansArr = []
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.push(e.target.value)
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.push(answerSet)
                    this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                    this.state.ansArr.push(answerSet)
                    this.setState({ ansArr: this.state.ansArr })
                } else {
                    let answerSet = { "answer": data.answer, "answerId": data.answerId, "answerIndex": index + 1 }
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.push(e.target.value)
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.push(answerSet)
                    this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                    this.state.ansArr.push(answerSet)
                    //remove more optons
                    // if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice == Object.values(this.props.fullQuestionData.data)[this.state.indexValue].maxChoice) {
                    if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length > Object.values(this.props.fullQuestionData.data)[this.state.indexValue].maxChoice) {
                        Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.splice(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length - 2, 1)
                        Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.splice(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.length - 2, 1)
                        this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                        this.state.ansArr.splice(this.state.ansArr.length - 2, 1)
                        this.setState({ ansArr: this.state.ansArr })
                    }
                    // }   
                }
            } else {
                let answerSet = {
                    "answer": data.answer, "answerId": data.answerId, "answerIndex": index + 1,
                    "specify": data.specify,
                }
                Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray = []
                Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer = []
                this.state.ansArr = []
                Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.push(e.target.value)
                Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.push(answerSet)
                this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                this.state.ansArr.push(answerSet)
                this.setState({ ansArr: this.state.ansArr })
            }
        }
        else {
            if (data.specify != true || data.removeOther != true) {
                let index = Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.indexOf(e.target.value)
                if (index > -1) {
                    this.setState({ answerIndex: index - 1 })
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.splice(index, 1)
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.splice(index, 1)
                    this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                }
                let ind = this.state.ansArr.includes(e.target.value)
                if (index > -1) {
                    this.state.ansArr.splice(ind, 1)
                }
            } else {
                Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer[0] = {
                    "answerId": data.answerId, "answer": data.answer,
                }
                let index = Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.indexOf(e.target.value)
                if (index > -1) {
                    this.setState({ answerIndex: index - 1 })
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.splice(index, 1)
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.splice(index, 1)
                    this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                }
                let ind = this.state.ansArr.includes(e.target.value)
                if (index > -1) {
                    this.state.ansArr.splice(ind, 1)
                }
            }
        }
    }
    //click on radio options
    setRadioOptions(e, data, index) {
        this.setState({ enterClicked: true, answerIndex: index + 1, ansArr: [] })
        Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray = []
        Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer = []
        Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.push(e.target.value)
        Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.push(data)
        this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
        this.state.ansArr.push(data)
    }
    checkRank(arrayData, ansId) {
        let rankValue = ""
        Object.values(arrayData)[this.state.indexValue].dbAnswer.forEach((data, index) => {
            if (data.answerId == ansId) {
                rankValue = data.rank
            }
        })
        return rankValue
    }
    createAnswer(data) {
        if (data.tooltipText) {
            var tooltipObj = {};
            data.tooltipText.map(rec => {
                tooltipObj[rec] = `<Tooltip title="${fullGlossary[rec]}" placement="${"topRight"}"><span class="tooltip-text">${rec}</span></ToolTip>`
            })
            var regExp = new RegExp(Object.keys(tooltipObj).join("|"), "gi");
            let str = data.answer.replace(regExp, function (matched) {
                return tooltipObj[matched];
            });
            return (<div className="answer-block" dangerouslySetInnerHTML={{ __html: str }} />)
        } else {
            return (<span className="answer-block">{data.answer}</span>)
        }
    }
    getRadioAnswer(data) {
        if (data.tooltipText) {
            var tooltipObj = {};
            data.tooltipText.map(rec => {
                tooltipObj[rec] = `<Tooltip title="${fullGlossary[rec]}" placement="${"topRight"}"><span class="tooltip-text">${rec}</span></ToolTip>`
            })
            var regExp = new RegExp(Object.keys(tooltipObj).join("|"), "gi");
            let str = data.answer.replace(regExp, function (matched) {
                return tooltipObj[matched];
            });
            return (<label htmlFor={data.answerId} className="answer-label"><div className="answer-block" dangerouslySetInnerHTML={{ __html: str }} /></label>)
        } else {
            return (<label htmlFor={data.answerId} className="answer-label"><span className="answer-block">{data.answer}</span></label>)
        }
    }
    selectKeyboardCheckMultiple(e, data, indexVal) {
        if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.includes(data.answerId)) {
            let indexToDelete = Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.indexOf(data.answerId)
            Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.splice(indexToDelete, 1)
            Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.splice(indexToDelete, 1)
            this.state.ansArr.splice(indexToDelete, 1)
            this.setState({ ansArr: this.state.ansArr })
        } else {
            Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.forEach((dbRec, dbInd) => {
                if (dbRec.specify) {
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.splice(dbInd, 1)
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.splice(dbInd, 1)
                    this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                    this.state.ansArr.splice(dbInd, 1)
                    this.setState({ ansArr: this.state.ansArr })
                }
                if (dbRec.removeOther) {
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.splice(dbInd, 1)
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.splice(dbInd, 1)
                    this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                    this.state.ansArr.splice(dbInd, 1)
                    this.setState({ ansArr: this.state.ansArr })
                }
            })
            if (!data.specify) {
                if (data.removeOther) {
                    let answerSet = {
                        "answer": data.answer, "answerId": data.answerId, "answerIndex": indexVal + 1,
                        "removeOther": data.removeOther,
                    }
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray = []
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer = []
                    this.state.ansArr = []
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.push(data.answerId)
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.push(answerSet)
                    this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                    this.state.ansArr.push(answerSet)
                    this.setState({ ansArr: this.state.ansArr })
                } else {
                    let answerSet = { "answer": data.answer, "answerId": data.answerId, "answerIndex": indexVal + 1 }
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.push(data.answerId)
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.push(answerSet)
                    this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                    this.state.ansArr.push(answerSet)
                    this.removeOptions()//remove more optons
                }
            } else {
                let answerSet = {
                    "answer": data.answer, "answerId": data.answerId, "answerIndex": indexVal,
                    "specify": data.specify,
                    // "specifyDescription": currentAnswerObject[i].specifyDescription 
                }
                Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray = []
                Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer = []
                this.state.ansArr = []
                Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.push(data.answerId)
                Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.push(answerSet)
                this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                this.state.ansArr.push(answerSet)
                this.setState({ ansArr: this.state.ansArr })
            }
        }

        if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length > Object.values(this.props.fullQuestionData.data)[this.state.indexValue].maxChoice) {
            Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.splice(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length - 2, 1)
            Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.splice(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.length - 2, 1)
            this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
            this.state.ansArr.splice(this.state.ansArr.length - 2, 1)
            this.setState({ ansArr: this.state.ansArr })
        }

    }
    keyboardRadio(data) {
        Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray = []
        Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer = []
        Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.push(data.answerId)
        Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.push(data)
        window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
        this.state.ansArr.push(data)
        this.setState({ ansArr: this.state.ansArr })
    }
    keyboardHandling(key, e, data) {
        var keyTyped = key;
        var keyIndex = KEYS.indexOf(keyTyped.toLowerCase());
        var currentAnswerObject = Object.values(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answers);
        for (var i in currentAnswerObject) {
            if (keyIndex == i) {
                var indexVal = Number(i);
                this.setState({ answerIndex: indexVal })
                if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].questionType == "Radio") {
                    this.setState({ ansArr: [] })
                    this.keyboardRadio(currentAnswerObject[i])
                }
                else if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].questionType == "Select") {
                    this.selectKeyboardCheckMultiple(e, currentAnswerObject[i], indexVal)
                }
                this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                if (document.querySelector('.highlight-answer')) {
                    document.querySelector('.highlight-answer').className = 'quest-answer-block';
                }
            }
        }
        if (key == "down") {
            if (this.state.answerIndex < Object.values(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answers).length) {
                if (this.state.answerIndex == 0)
                    this.setState({ answerIndex: 1 }, this.navigateAnswer)
                else
                    this.setState({ answerIndex: this.state.answerIndex + 1 }, this.navigateAnswer)
            }
        }
        if (key == "up") {
            if (this.state.answerIndex > 1)
                this.setState({ answerIndex: this.state.answerIndex - 1 }, this.navigateAnswer)
        }
        if (key == "left") {
            this.backArrowFunction()
        }
        if (key == "right") {
            if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].questionType == "Radio") {
                if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length == 1)
                    this.nextClicked()
            } else if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].questionType == "Select") {

                console.log("11", !(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length < Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice))
                console.log("222", !(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length > Object.values(this.props.fullQuestionData.data)[this.state.indexValue].maxChoice))

                if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice == Object.values(this.props.fullQuestionData.data)[this.state.indexValue].maxChoice) {
                    if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length == Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice)
                        this.nextClicked()
                }
                else if (!(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length < Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice) && !(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length > Object.values(this.props.fullQuestionData.data)[this.state.indexValue].maxChoice)) {
                    this.nextClicked()
                }
            }
            window.scrollTo(0, 0);
            if (document.querySelector('.highlight-answer'))
                document.querySelector('.highlight-answer').className = "quest-answer-block"
        }
        if (key == "enter" || key == "tab" || key == "space") {
            if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].questionType == "Radio") {
                if ((Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length <= 0) && (document.querySelector('.highlight-answer')) && (key == "enter")) {
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray = []
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer = []
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.push(document.querySelector(".highlight-answer input").id)
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.push({ "answer": document.querySelector(".highlight-answer label span").textContent, "answerId": document.querySelector(".highlight-answer input").id })
                    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
                    this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                    // document.querySelector('.highlight-answer').className = 'quest-answer-block';
                } else if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length && (key == "tab")) {
                    this.nextClicked()
                } else if (document.querySelector('.highlight-answer') && (key == "space" || key == "enter")) {
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray = []
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer = []
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.push(document.querySelector(".highlight-answer input").id)
                    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.push({ "answer": document.querySelector(".highlight-answer label span").textContent, "answerId": document.querySelector(".highlight-answer input").id })
                    this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                    // document.querySelector('.highlight-answer').className = 'quest-answer-block';
                } else if (document.querySelector('.selected-answer') && (key == "enter")) {
                    this.nextClicked()
                }
            }
            else if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].questionType == "Select") {
                if ((Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length <= 0) && (document.querySelector('.highlight-answer')) && (key == "enter")) {
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.push(document.querySelector(".highlight-answer input").id)
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.push({ "answer": document.querySelector(".highlight-answer label span").textContent, "answerId": document.querySelector(".highlight-answer input").id })
                    this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                    this.state.ansArr.push({ "answer": document.querySelector(".highlight-answer label span").textContent, "answerId": document.querySelector(".highlight-answer input").id })
                    this.setState({ ansArr: this.state.ansArr })
                    //remove more options
                    this.removeOptions()
                    document.querySelector('.highlight-answer').className = 'quest-answer-block';
                } else if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length && (key == "tab")) {
                    if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice == Object.values(this.props.fullQuestionData.data)[this.state.indexValue].maxChoice) {
                        if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length == Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice)
                            this.nextClicked()
                    }
                    else if (!(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length < Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice) || (!Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length > Object.values(this.props.fullQuestionData.data)[this.state.indexValue].maxChoice))
                        this.nextClicked()
                } else if (document.querySelector('.highlight-answer') && (key == "space" || key == "enter")) {
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.push(document.querySelector(".highlight-answer input").id)
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.push({ "answer": document.querySelector(".highlight-answer label span").textContent, "answerId": document.querySelector(".highlight-answer input").id })
                    this.state.ansArr.push({ "answer": document.querySelector(".highlight-answer label span").textContent, "answerId": document.querySelector(".highlight-answer input").id })
                    this.setState({ ansArr: this.state.ansArr })
                    // document.querySelector('.highlight-answer').className = 'quest-answer-block';
                    this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                    if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length == Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice) {
                        this.checkValidationMsg()
                    }
                    //remove more options
                    this.removeOptions()
                } else if (document.querySelector('.selected-answer') && (key == "enter")) {
                    if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice == Object.values(this.props.fullQuestionData.data)[this.state.indexValue].maxChoice) {
                        if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length == Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice)
                            this.nextClicked()
                    }
                    else if (!(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length < Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice) || (!Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length > Object.values(this.props.fullQuestionData.data)[this.state.indexValue].maxChoice))
                        this.nextClicked()
                }
            }
        }
    }
    //for radio and select type questions
    getQuestions(arrayData) {
        console.log("test1: ",Object.values(Object.values(arrayData)[this.state.indexValue].answers))
        var list = []
        if (Object.values(arrayData)[this.state.indexValue]) {
            if (Object.values(Object.values(arrayData)[this.state.indexValue].answers).length > 0 ){
                Object.values(Object.values(arrayData)[this.state.indexValue].answers).forEach((data, index) => {
                    if (Object.values(arrayData)[this.state.indexValue].answerArray.includes(data.answerId))
                        var answerClass = "quest-answer-block animated pulse selected-answer";
                    else
                        var answerClass = "quest-answer-block";
                    if (Object.values(arrayData)[this.state.indexValue].questionType == "Select") {
                        list.push(
                            <div key={index} className={answerClass}>

                                <input name="radio-button" className="radio-answer" checked={Object.values(arrayData)[this.state.indexValue].answerArray.includes(data.answerId)} type="checkbox" onChange={(e) => this.setCheckboxOptions(e, data, index)} value={data.answerId} id={data.answerId} />
                                <label htmlFor={data.answerId} className="answer-label">
                                    {this.createAnswer(data)}
                                </label>

                            </div>)
                    }
                    if (Object.values(arrayData)[this.state.indexValue].questionType == "Radio") {
                        list.push(
                            <div key={index} className={answerClass}>
                                <input name="radio-button" className="radio-answer" checked={Object.values(arrayData)[this.state.indexValue].answerArray.includes(data.answerId)} type="radio" onChange={(e) => this.setRadioOptions(e, data, index)} value={data.answerId} id={data.answerId} />
                                {this.getRadioAnswer(data)}
                            </div>)
                    }
                })
                return list
                // return (
                //     <>
                //         <KeyboardEventHandler
                //             handleKeys={['all']}
                //             onKeyEvent={(key, e) => this.keyboardHandling(key, e, "data")} />
                //         {list}
                //     </>
                // )
            } else if(Object.values(arrayData)[this.state.indexValue].questionType === "Description"){
                console.log("desc qn")
                if(Object.values(arrayData)[this.state.indexValue].id === '63285f9b64d64b30a01db4ac' || Object.values(arrayData)[this.state.indexValue].questionId === '63285f9b64d64b30a01db4ac'){
                    list.push(
                        <div className='form-group'>
                            <input name='custom-answer' className='form-control' type='text' value={this.state.customPhone} onChange={(e)=> this.setState({customPhone: e.target.value})} />
                        </div>
                    )
                } else if(Object.values(arrayData)[this.state.indexValue].id === '63285f9b64d64b30a01db4ad' || Object.values(arrayData)[this.state.indexValue].questionId === '63285f9b64d64b30a01db4ad'){
                    list.push(
                        <div className='form-group'>
                            <input name='custom-answer' className='form-control' type='text'  value={this.state.customZip} onChange={(e)=> this.setState({customZip: e.target.value})} />
                        </div>
                    )
                }
                return list;
            }
        }
    }
    checkDisabled() {
        if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].questionType == "Radio") {
            if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length == 1) {
                window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
                return false
            } else {
                return true
            }
        }
        if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].questionType == "Select") {
            if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length < Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice || Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length > Object.values(this.props.fullQuestionData.data)[this.state.indexValue].maxChoice)
                return true
            else {
                // window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
                return false
            }

        } else if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].questionType == "Description") {
            if(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].id === '63285f9b64d64b30a01db4ac'){
                if(this.state.customPhone)
                    return true;
                else
                    return false;
            } else if(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].id === '63285f9b64d64b30a01db4ad'){
                if(this.state.customZip)
                    return true;
                else
                    return false;
            }
        }
        if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].questionType == "Rank") {
            if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.length == 1 && Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer[0].removeOther == true) {
                return false
            } else {
                if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice == Object.values(this.props.fullQuestionData.data)[this.state.indexValue].maxChoice) {
                    if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length == Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice && Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length == Object.values(this.props.fullQuestionData.data)[this.state.indexValue].maxChoice) {
                        window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
                        return false
                    } else {
                        return true
                    }
                }
                else {
                    if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length < Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice || Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length > Object.values(this.props.fullQuestionData.data)[this.state.indexValue].maxChoice)
                        return true
                    else {
                        window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
                        return false
                    }

                }
            }

        }
    }

    getRankQuestions(arrayData) {
        var list = []
        if (Object.values(arrayData)[this.state.indexValue]) {
            Object.values(Object.values(arrayData)[this.state.indexValue].answers).forEach((data, index) => {
                if (Object.values(arrayData)[this.state.indexValue].answerArray.includes(data.answerId)) {
                    if (data.removeOther) {
                        var answerClass = "quest-answer-block  animated pulse selected-answer";
                    } else {
                        var answerClass = "quest-answer-block quest-rank animated pulse selected-answer";
                    }
                    switch (this.checkRank(arrayData, data.answerId)) {
                        case 1:
                            answerClass = answerClass + ' first-rank';
                            break;
                        case 2:
                            answerClass = answerClass + ' second-rank';
                            break;
                        case 3:
                            answerClass = answerClass + ' three-rank';
                            break;
                        case 4:
                            answerClass = answerClass + ' four-rank';
                            break;
                    }
                }
                else
                    var answerClass = "quest-answer-block";
                list.push(
                    <div key={index} className={answerClass}>
                        <input name="radio-button" className="radio-answer" checked={Object.values(arrayData)[this.state.indexValue].answerArray.includes(data.answerId)} type="checkbox" onChange={(e) => this.setRankOptions(e, data, index)} value={data.answerId} id={data.answerId} />
                        <label htmlFor={data.answerId} className="answer-label"><span className="answer-block">{data.answer}
                        </span></label>
                    </div>
                )
            })
        }
        return list
    }
    setNewRankArray() {
        let newArray = []
        let dbArray = []
        Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.forEach((data, index) => {
            dbArray.push({ "answer": data.answer, "answerId": data.answerId, "rank": index + 1 })
            newArray.push({ "answer": data.answer, "answerId": data.answerId, "rank": index + 1 })
        })
        this.state.ansArr = newArray
        Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer = dbArray
        this.setState({ ansArr: this.state.ansArr })
        // this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTIONS")
    }

    setRankOptions(e, data, recordIndex) {
        Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.forEach((dbRec, dbInd) => {
            if (dbRec.removeOther) {
                Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.splice(dbInd, 1)
                Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.splice(dbInd, 1)
                this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                this.state.ansArr.splice(dbInd, 1)
                this.setState({ ansArr: this.state.ansArr })
            }
        })
        if (data.removeOther) {
            let answerSet = {
                "answer": data.answer, "answerId": data.answerId, "answerIndex": recordIndex + 1,
                "removeOther": data.removeOther,
            }
            Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray = []
            Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer = []
            this.state.ansArr = []
            Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.push(e.target.value)
            Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.push(answerSet)
            this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
            this.state.ansArr.push(answerSet)
            this.setState({ ansArr: this.state.ansArr })
        } else {
            if ((Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length <= 0)) {
                Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.push(data.answerId)
                Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.push({ "answer": data.answer, "answerId": data.answerId, "rank": Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length })
                this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                this.state.ansArr.push({ "answer": data.answer, "answerId": data.answerId, "rank": Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length })
                this.setState({ ansArr: this.state.ansArr })
            } else {
                if ((Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray).includes(data.answerId)) {
                    let index = (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray).indexOf(data.answerId)
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.splice(index, 1)
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.splice(index, 1)
                    this.state.ansArr.splice(index, 1)
                    this.setState({ ansArr: this.state.ansArr })
                    this.setNewRankArray()
                    if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice == Object.values(this.props.fullQuestionData.data)[this.state.indexValue].maxChoice) {
                        if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length < Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice) {
                            this.checkValidationMsg()
                        }
                    }
                } else {
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.push(data.answerId)
                    Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.push({ "answer": data.answer, "answerId": data.answerId, "rank": Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length })
                    this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                    this.state.ansArr.push({ "answer": data.answer, "answerId": data.answerId, "rank": Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length })
                    this.setState({ ansArr: this.state.ansArr })
                    //remove more options
                    if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice == Object.values(this.props.fullQuestionData.data)[this.state.indexValue].maxChoice) {
                        if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length > Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice) {
                            Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.splice(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length - 2, 1)
                            Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.splice(Object.values(this.props.fullQuestionData.data)[this.state.indexValue].dbAnswer.length - 2, 1)
                            this.props.saveSurvey(this.props.fullQuestionData.data, "GET_QUESTION_BY_CATEGORY")
                            this.state.ansArr.splice(this.state.ansArr.length - 2, 1)
                            this.setState({ ansArr: this.state.ansArr })
                            this.setNewRankArray()
                            if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length == Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice) {
                                this.checkValidationMsg()
                            }
                        }
                        if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].answerArray.length == Object.values(this.props.fullQuestionData.data)[this.state.indexValue].minChoice) {
                            this.checkValidationMsg()
                        }
                    }
                }
            }
        }

    }
    questionCreate() {
        if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue].tooltipText !== undefined && Object.values(this.props.fullQuestionData.data)[this.state.indexValue].tooltipText.length > 0) {
            var tooltipObj = {};
            Object.values(this.props.fullQuestionData.data)[this.state.indexValue].tooltipText.map(rec => {
                tooltipObj[rec] = `<Tooltip title="${fullGlossary[rec]}" placement="${"top"}"><span class="tooltip-text">${rec}</span></ToolTip>`
            })
            var regExp = new RegExp(Object.keys(tooltipObj).join("|"), "gi");
            let str = Object.values(this.props.fullQuestionData.data)[this.state.indexValue].question.replace(regExp, function (matched) {
                return tooltipObj[matched];
            });
            return (<div className="question-title" dangerouslySetInnerHTML={{ __html: str }} />)
        } else {
            return (
                <div className="question-title">{Object.values(this.props.fullQuestionData.data)[this.state.indexValue].question.split('\n').map((item, key) => {
                    return <span key={key}>{item}<br /></span>
                })}</div>
            )
        }

    }
    render() {
        if (Object.values(this.props.fullQuestionData.data)[this.state.indexValue] != undefined) {
            var time = Object.values(this.props.fullQuestionData.data).length
            var id;
            if (this.state.orderArray.length > 0) {
                id = this.state.orderArray[this.state.orderArray.length - 1]
            } else {
                id = 0
            }
        }
        return (
            <React.Fragment>
                <MainHeader />
                <SectionHeader sectionName="Organization" calledButton={this.state.clickedButton} lastIndex={id} currentValue={Object.values(this.props.fullQuestionData.data)[this.state.indexValue] != undefined && Object.values(this.props.fullQuestionData.data)[this.state.indexValue].order} timeValue={time} total={Object.values(this.props.fullQuestionData.data).length} />
                <div className="content-wrap content-wrap-with-sectionHeader">
                    {this.props.questionLoader.loading ? (
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
                            <img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/spinning.gif" />
                        </div>
                    ) : (
                        <div className="container animated fadeIn">
                            {Object.values(this.props.fullQuestionData.data).length > 0 && (
                                <div className="">
                                    {(Object.values(this.props.fullQuestionData.data).length > 0 && (this.state.indexValue <= Object.values(this.props.fullQuestionData.data).length - 1)) && (
                                        <div className="animated fadeIn single-question-block">
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="question-main-header">
                                                        <div className="question-total-count">
                                                            Question <b>{this.state.pageIndex}</b>
                                                        </div>
                                                    </div>
                                                    {Object.values(this.props.fullQuestionData.data)[this.state.indexValue].questionHeader && (
                                                        <Tag color="#7F2020" className="q-tag"> {Object.values(this.props.fullQuestionData.data)[this.state.indexValue].questionHeader}</Tag>
                                                    )}
                                                    <div className="question-title">
                                                        {this.questionCreate()}
                                                    </div>
                                                    <div className="quest-description mb-3" >{Object.values(this.props.fullQuestionData.data)[this.state.indexValue].questionDescription}</div>
                                                    <div className="answer-option-block">
                                                        {this.getQuestions(this.props.fullQuestionData.data)}
                                                        {Object.values(this.props.fullQuestionData.data)[this.state.indexValue].questionType == "Rank" && (
                                                            <div>{this.getRankQuestions(this.props.fullQuestionData.data)}</div>
                                                        )}
                                                        {this.getSpecifyMessage(this.props.fullQuestionData.data)}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="section-image">
                                                        <img className="img-fluid" src={this.state.selectedImage} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="button-block survey-prev">
                                                <div>
                                                    <Button disabled={this.state.indexValue == 0} onClick={() => this.backArrowFunction()} >BACK</Button>
                                                </div>
                                                <div>
                                                    <span className="validation-error">{this.state.minValidMsg}</span>
                                                        {(this.state.indexValue + 1 == Object.values(this.props.fullQuestionData.data).length) ? (
                                                            <Button className="button-right-next" disabled={this.checkDisabled()} onClick={() => this.nextClicked()}>
                                                                I AM DONE
                                                            </Button>
                                                        ) : (
                                                            <Button className="button-right-next" disabled={this.checkDisabled()} onClick={() => this.nextClicked()}>
                                                                NEXT
                                                            </Button>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="d-block d-sm-none mt-2 text-center">
                                                <span className="validation-error">{this.state.minValidMsg}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                    )}
                    {this.state.navigate ? <Redirect to={this.state.path} /> : null}
                    {this.state.isRedirect ? <Redirect to={this.state.redirectPath} /> : null}
                </div>
            </React.Fragment>
        )
    }
}
function mapStateToProps(state) {
    return { userSectionData: state.userSectionData, auth: state.auth, userLoader: state.userLoader, questionLoader: state.questionLoader, fullQuestionData: state.fullQuestionData }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        // getPage: getPage,
        questStartRequest: questStartRequest,
        getUserSectionData: getUserSectionData,
        // getSurveyData: getSurveyData,
        getQuestionById: getQuestionById,
        saveSurvey: saveSurvey,
        getAuthentication: getAuthentication,
    }, dispatch)
}
export default connect(mapStateToProps, matchDispatchToProps)(Organization)