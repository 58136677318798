import React, { useEffect,useRef,useState} from 'react'
import { useForm } from "react-hook-form";
import { LoadingOutlined } from '@ant-design/icons'
import axios from 'axios'
import { Redirect} from 'react-router-dom'
import { toast } from 'react-toastify'
import apiURL from '../../env'
const ResetPassword=(props)=>{
    const [path, setPath] = useState("")
    const { register, handleSubmit, watch, errors } = useForm();
    const password = useRef({});
    password.current = watch("password", "");
    const onSubmit = data => {
        if(data){
            axios({
                method: 'POST',
                url: apiURL + 'leads/reset-password?access_token=' + props.match.params.id,
                data: { newPassword: data.password }
            }).then((res) => {
                toast.success("Your password has been reset successfully", {
                    position: "top-right"
                });
                setTimeout(() => {
                    setPath("/")
                }, 1000);
            }).catch((err) => {
                console.log("err.response.data.error",err.response,"333", err.response.data.error)
                if (err.response.data.error) {
                    toast.error("Your token has been expired", {
                        position: "top-right"
                    });
                }
            })
        }
      
    }
    const cancelReset=()=> {
        setPath("/")
    }
    return(
        <div className="content-wrap login-wrap overlay" style={{ backgroundImage: "url('https://daaistore.s3-ap-southeast-1.amazonaws.com/bg-login.jpg')" }}>
        <div className="container">
            <div className="login-form">
            <h2>Reset Password</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                            <input className="form-control" name="password" placeholder="New Password" type="password" ref={register({
                                required: "Password is required",
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                                    message: "Password needs to have at least 8 characters, with upper and lower case letters, and at least one special character."
                                }
                            })} />
                            {errors.password && <span className="login-error-msg">{errors.password.message}</span>}
                </div>
                <div className="form-group">
                    <input name="confirmPassword" type="password" className="form-control" placeholder="Confirm Password" ref={register({
                        validate: value =>
                            value === password.current || "The passwords do not match",
                        required: 'Confirm Password is required'
                    })} />
                    {errors.confirmPassword && <span className="login-error-msg">{errors.confirmPassword.message}</span>}
                </div>
                <div className="rest-btns d-flex justify-content-between">
                    <button className="rest-cancel-btn login-submit-btn" onClick={cancelReset}>CANCEL</button>
                    <button className="login-submit-btn" type="submit" >RESET</button>
                </div>
            </form>
            {path!="" ? <Redirect to={path} /> : null}

        </div>
        </div>
        </div>
    )
}
export default ResetPassword