const initialState = {
    sponsors: []
}
export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_SPONSORS":
            return {
                ...state,
                sponsors: action.payload
            }
            break;
    }
    return state
}