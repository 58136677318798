import React, { useState, useRef} from 'react'
import { useForm } from "react-hook-form";
import { Link, Redirect} from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import axios from 'axios'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import { getAuthentication} from '../../Actions/loginCheck'
import apiURL  from '../../env'
import store from '../../Store/configureStore'
import MainHeader from '../../Header/main'
import {toast} from 'react-toastify'
const Login=(props)=>{
    const [invalidMsg, setInvalidMsg] = useState("")
    // const [focusChanged, setFocusChanged] = useState(false)
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, watch, errors } = useForm();
    const [path, setPath] = useState("")
    const onSubmit = data =>{
        if(data){
            setLoading(true)
            axios({
                method: 'POST',
                url: apiURL+'leads/login',
                data: { email: data.email, password: data.password }
            }).then((response) => {
                setLoading(false)
                toast.success("You are successfully logged in", {
                    position: "top-right"
                });
                localStorage.token = response.data.token.id
                axios.defaults.headers.common['Authorization'] = response.data.token.id
                props.getAuthentication()
                if (response.data.sponsorId != undefined && response.data.sponsorId !="") {
                    let expirationDate = new Date(response.data.sponsorLockSixMonthExpiry)
                    let localDate = new Date()
                    if (localDate > expirationDate) {
                        setPath("/sponsor")
                    } else {
                        setPath("/dashboard")
                    }
                } else {
                    setPath("/sponsor")
                }
            }).catch((err) => {
                setLoading(false)
                // setFocusChanged(false)
                if (err.response == undefined) {
                    setInvalidMsg(err.message)
                } else {
                    if (err.response.data.error.statusCode == 401) {
                        setInvalidMsg("Invalid email or password")
                    }
                }
                store.dispatch({
                        type: "GET_AUTH",
                        payload: { status: false, loaded: true, authType: "LOGIN_FAILED" }
                    })
                })
        }
    }
    return (
        <React.Fragment>
        <MainHeader/>
            <div className="content-wrap login-wrap overlay" style={{ backgroundImage: "url('https://daaistore.s3-ap-southeast-1.amazonaws.com/bg-login.jpg')"}}>
            <div className="container">
                <div className="login-form">
                    <h2>Start Now</h2>
                    <span className="login-sub-text">If you are <span style={{ color: '#7F2020' }}>Registered User, </span> please  <span style={{color:'#7F2020'}}>Sign in</span> below.</span>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <input className="form-control" autoFocus name="email" placeholder="Email" ref={
                                register({
                                    pattern: {
                                        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: 'Invalid E-mail address' // JS only: <p>error message</p> TS only support string
                                    },
                                    required: 'Email is required'
                                })
                            } 
                            onFocus={() => setInvalidMsg("")}
                            />
                            {errors.email && <span className="login-error-msg">{errors.email.message}</span>}
                        </div>
                        <div className="form-group">
                                <input className="form-control" name="password" placeholder="Password" type="password" ref={register({ required: true })} onFocus={() => setInvalidMsg("")}/>
                            {errors.password && <span className="login-error-msg">Password is required</span>}
                        </div>
                        <button className="login-submit-btn btn w-100" type="submit">
                            Login {loading&&<LoadingOutlined className="btn-loader"/>}
                        </button>
                        <div className="form-group">
                            {invalidMsg != "" && (
                                <span><label className="login-error-msg">{invalidMsg}</label></span>
                            )}
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                        <span className="signup-msg">New User? <Link to="/registration">Click here</Link> to Sign up </span>
                        <Link to="/forgot-password" className="forget-link" style={{color:'#000',textDecoration:'underline'}}>Forgot Password?</Link>
                        </div>
                    </form>
                </div>
            </div>
            {path!=""&&<Redirect to={path}/>}
        </div>
        </React.Fragment>
    )
}
const mapStateToProps=(state)=>{
    return{}
}
const mapDispatchToProps=(dispatch)=>{
    return bindActionCreators({ getAuthentication: getAuthentication }, dispatch)
}
export default connect(mapStateToProps,mapDispatchToProps)(Login)