import React, { useState} from 'react'
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux'
import HomeHeader from '../../Header/homeHeader';
import MainHeader from '../../Header/main';
import {Link} from 'react-router-dom'
const HomeMain=(props)=>{
	const [homeContent, setHomeContent] = useState({
		"takeAssessment": {
						"header":"Data, Analytics and AI Readiness Assessment",
						"subHeader":"Preparing for the Data-Driven and AI-Ready Organization",
						"firstContent": "An IDC's self-assessment tool  that can be used by C-suite and Head of Departments of business enterprises to capture a comprehensive analysis of their organizational and technological readiness to invest in data and analytics initiatives, and provide a guidance to ready themselves to harness AI, and stay prepared for the future of intelligent enterprise.",
						"secondContent":"Take the assessment to get IDC's independent analysis of your organization's readiness on the six key dimensions: organization, people, processes, technology, data and use case.",
						"bgImg":"https://daaistore.s3-ap-southeast-1.amazonaws.com/bg-organization.jpg"
						},
		"organization":{
						"header": "Organization",
						"firstContent": "Organization dimension is where changes start. It requires not only a data strategy aligned with the company's business goals, but also the provision of organizational support including clear ownership,  effective stakeholder collaboration and a well-planned scope that progresses from data visualization, data analytics, to data science and data monetization.",
						"secondContent": "",
						"bgImg": "https://daaistore.s3-ap-southeast-1.amazonaws.com/bg-organization.jpg"

						},
		"people": {
						"header": "People",
						"firstContent": "People dimension is where changes receive rapports. It requires effective management of data skills, data literacy and a pro-data work culture to accelerate organizational change both from bottom-up and top-down approach.",
						"secondContent": "",
						"bgImg": "https://daaistore.s3-ap-southeast-1.amazonaws.com/bg-people.jpg"
			},
		"processes": {
						"header": "Processes",
						"firstContent": "Process dimension is where changes are disciplined. It include metrics that provide transparency for different aspects of data initiatives, presence of data operations, and their alignment with business/IT processes updates.",
						"secondContent": "",
						"bgImg": "https://daaistore.s3-ap-southeast-1.amazonaws.com/bg-process.jpg"
						},
		"technology": {
						"header": "Technology",
						"firstContent": "Technology dimension is where changes are facilitated by leveraging advancement in the technological world. It includes whether state of the art technology solutions are in place to cover 3 layers of data value delivery needs - the data platform layer, the analytics tool layer, and the application consumption layer.",
						"secondContent": "",
						"bgImg": "https://daaistore.s3-ap-southeast-1.amazonaws.com/bg-technology.jpg"
						},
		"data": 	{
						"header": "Data",
						"firstContent": "Data dimension is where changes are fueled. AI applications require continuous input of data to become and stay functional - much like an automobile cannot run without petrol. This is where AI applications are fundamentally different from traditional software applications. This dimension is to evaluate a company's data asset as the raw material, such as utilization, safety, accessibility and quality. These aspects are applicable to data analytics solutions as well, though to a less extent. ",
						"secondContent": "",
						"bgImg": "https://daaistore.s3-ap-southeast-1.amazonaws.com/bg-data.jpg"
					},
		"useCase": {
					"header": "Use Case",
					"firstContent": "Use case dimension is where changes are materialized. Assessment result of this dimension, should correlate positively with assessment results of all above dimensions, it serves as a cross check point for companies to review their investment plan.",
					"secondContent": "",
					"bgImg": "https://daaistore.s3-ap-southeast-1.amazonaws.com/bg-usecase.jpg"

				},

	})
	const [state, setstate] = useState(homeContent.takeAssessment)
	const sectionClick=(content)=>{
		setstate(homeContent[content])
	}
    return (
		<React.Fragment>
		<MainHeader/>
			<div className="home" style={{ backgroundImage:`url(${state.bgImg})` }}>
    		<div className="container">
    			<div className="row home-content-wrap">
    				<div className="col-lg-6">
    					<div className="home-box row">
							<div className="col-6 col-md-4 col-box" onClick={() => sectionClick("organization")}>
									<div className={state.header == "Organization" ? "icon-box active" :"icon-box"}>
	    							<img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-organization.svg" className="img-fluid"/>
	    							<label>Organization</label>
	    						</div>
    						</div>
							<div className="col-6 col-md-4 col-box" onClick={() => sectionClick("people")}>
									<div className={state.header == "People" ? "icon-box active" : "icon-box"}>
	    							<img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-people.svg" className="img-fluid"/>
	    							<label>People</label>
	    						</div>
    						</div>
							<div className="col-6 col-md-4 col-box" onClick={() => sectionClick("processes")}>
									<div className={state.header == "Processes" ? "icon-box active" : "icon-box"}>
	    							<img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-process.svg" className="img-fluid"/>
	    							<label>Processes</label>
	    						</div>
    						</div>
							<div className="col-6 col-md-4 col-box" onClick={() => sectionClick("technology")}>
									<div className={state.header == "Teachnology" ? "icon-box active" : "icon-box"}>
	    							<img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-technology.svg" className="img-fluid"/>
	    							<label>TECHNOLOGY</label>
	    						</div>
    						</div>
							<div className="col-6 col-md-4 col-box" onClick={() => sectionClick("data")}>
									<div className={state.header == "Data" ? "icon-box active" : "icon-box"}>
	    							<img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-data.svg" className="img-fluid"/>
	    							<label>DATA</label>
	    						</div>
    						</div>
							<div className="col-6 col-md-4 col-box" onClick={() => sectionClick("useCase")}>
									<div className={state.header == "Use Case" ? "icon-box active" : "icon-box"}>
	    							<img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-use-case.svg" className="img-fluid"/>
	    							<label>USE CASE</label>
	    						</div>
    						</div>
    					</div>
    				</div>
    				<div className="col-lg-6">
    					<div className="home-content">
    						<h1>{state.header}</h1>
							<h4>{state.subHeader}</h4>
    						<p>{state.firstContent}</p>
    						<p>{state.secondContent}</p>
							<Link className="white-btn" to={((props.auth.authData == undefined) ? "/login" : ((props.auth.authData.sponsorId) ? "/dashboard" : "/sponsor"))}>Get Started</Link>
    					</div>
    				</div>
    			</div>
    		</div>
    	</div>
		</React.Fragment>
    )
}
const mapStateToProps=(state)=>{
	return {
		auth:state.auth
	}
}
const mapDispatchToProps=(dispatch)=>{
	return bindActionCreators({},dispatch)
}
export default connect(mapStateToProps,mapDispatchToProps)(HomeMain)