import { combineReducers } from 'redux'
// import app from './app';
import authLoader from './authLoader'
import currentPage from './getPage'
import auth from './loginCheck'
import urlData from './getUrlData'
import getSponsors from './getSponsors'
import userLoader from './setUserDataLoader'
import userSectionData from './getUserData'
import fullQuestionData from './getQuestionById'
import questionLoader from './questionLoader'
import leadSurvey from './getSurveyData'
import toggleModal from './togglePop'
import trackTime from './timeStamp'
// import getSurvey from './getSurvey'
// import getCategories from './getCategories'
// import getQuestionById from './getQuestionById'
// import authStatus from './authStatus'
// import questionLoader from './questionLoader'

// import urlData from './getUrlData'
// import priorityStatus from './setPriority'
// import currentPage from './getPage'
const allReducers = combineReducers({
    authLoader, currentPage,toggleModal, auth, urlData, getSponsors, userLoader, userSectionData, fullQuestionData, questionLoader, leadSurvey,
    trackTime
    // app, authLoader, getSurvey, getCategories, getQuestionById, getSponsors,
    // authStatus, questionLoader, toggleModal, leadSurvey, urlData, priorityStatus, currentPage
});

export default allReducers