import React, { useEffect, useState} from 'react';
import MainHeader from '../Header/main';
import { Link, Redirect} from 'react-router-dom'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SpiderChart from './spiderChart'
import BlockChart from './blockChart'
import axios from 'axios'
import apiURL from '../env'
import {Button} from 'antd'
import {getUserSectionData} from '../Actions/getUserData'
const FinalResult=(props)=>{
	useEffect(() => {
		props.getUserSectionData()
	}, [])
	const [buttonText, setButton] = useState("Download Complete Report")
	const [loadingStatus, setLoading] = useState(false)
	let sectionName={
	"Organization Dimension": "Organization",
	"People Dimension":"People",
	"Process Dimension": "Process",
	"Technology Dimension": "Technology",
	"Data Dimension":"Data",
	"Use Case Dimension":"Use Case"
	}
	let resultObj = {
		"Beginner": 0,
		"Practitioner": 2,
		"Apprentice": 1,
		"Expert": 3,
		"Leader": 4
	}
	let numObj={
		1:"One",
		2:"Two",
		3:"Three",
		4:"Four",
		5:"Five"
	}
	let useCaseResult = props.auth.authData.useCaseResult
	let allArr = [resultObj[props.auth.authData.orgStage], resultObj[props.auth.authData.dataStage], resultObj[props.auth.authData.peopleStage], resultObj[props.auth.authData.processStage], resultObj[props.auth.authData.technologyStage]]
	let numberArr = allArr.filter(rec => rec != undefined)
	let total = numberArr.reduce((a, b) => a + b, 0)
	let result=total/5
	const getLabelValue=()=>{
		if(result<0.8){
			return "Beginner"
		}else if(result<1.6){
			return "Apprentice"
		}else if(result<2.4){
			return "Practitioner"
		}else if(result<3.2){
			return "Expert"
		}else{
			return "Leader"
		}
	}
	const generatePdf = () => {
		setLoading(true)
		setButton("Your report is being prepared, please wait.")
		axios({
			method: "GET",
			url: apiURL + 'survey/recommendationReport?access_token=' + localStorage.token
		}).then((res) => {
			setLoading(false)
			setButton("Download Complete Report")
		}).catch((err) => {
			setLoading(false)
			setButton("Download Complete Report")
		})
	}
	const getDimension=()=>{
		let catArr=[]
		props.userSectionData.data.length>0&&props.userSectionData.data.forEach(rec=>{
			if(rec.isCompleted){
				catArr.push(sectionName[rec.categoryName])
			}
		})
		if(catArr.length){
			let originalArray =[...catArr]
			catArr.length = (catArr.length) - 1
			let finalString=catArr.join(', ')
			return finalString + " and " + originalArray[originalArray.length-1]
		}
	}
	const getUseCaseText=()=>{
		if(result<=2&&useCaseResult>15){
			return "You scored significantly higher in implementation results, as compared to the enabling capabilities. The discrepancy could suggest sustainability issues of your data initiatives."
		}else if(result>2&&useCaseResult>15){
			return "Your scores in the enabling capabilities and implementation results are both placed in the higher range. They are mostly aligned and progressing well."
		}else if(result<=2&&useCaseResult<=15){
			return "Your scores in the enabling capabilities and implementation results are both placed in the lower range. There is much to improve in the context of data readiness."
		}else if(result>2&&useCaseResult<=15){
			return "You scored significantly higher in the enabling capabilities, as compared to the implementation results. The discrepancy suggests missed opportunities to turn your capabilities into business value."
		}
	}
    return(
        <div>
            <React.Fragment>
			<MainHeader/>
		        <div className="content-wrap">
		            <div className="container">
		            	<div className="recommendation-wrap">
							<h3 className="text-center">Thank you for participating!</h3>
							{/* <b>You have completed the readiness assessment for advanced analytics and AI on {numberArr.length} of all 6 dimensions.</b>*/}
							<div className="avg-box mb-4">
								<div className="avg-box-item">
									<label>You scored an average</label>
									<h4>{result}/4</h4>
								</div>
								<div className="avg-box-item">
									<label>{(getLabelValue() == "Apprentice" || getLabelValue()=="Expert")?"You are an":"You are a"}</label>
									<h4>{getLabelValue()}</h4>
								</div>
							</div> 
							{resultObj[props.auth.authData.useCaseStage] != undefined && (
							<ul className="avg-info">
								{/* <li>
									<img src="./assets/images/icon-info.svg" className="img-fluid" />
									<p><b>You scored an average of {result} (out of 4)</b> on the completed dimensions - {getDimension()}. You <b>ranked higher than 12%</b> of survey respondents. You are categorized as {(getLabelValue() == "Apprentice" || getLabelValue() == "Expert") ? "an " + getLabelValue().toLowerCase() : "a " + getLabelValue().toLowerCase()}.</p>
								</li> */}
								
									
									{/* <li>
										<img src="./assets/images/icon-info.svg" className="img-fluid" />
										<p>{"Over-performing (if overall maturity <= 2, use case maturity > 15%)"}</p>
									</li>
									<li>
										<img src="./assets/images/icon-info.svg" className="img-fluid" />
										<p>{"Ideal (if overall maturity > 2, use case maturity > 15%)"}</p>
									</li>
									<li>
										<img src="./assets/images/icon-info.svg" className="img-fluid" />
										<p>{"Needs Improvement (if overall maturity <= 2, use case maturity <= 15%)"}</p>
									</li>
									<li>
										<img src="./assets/images/icon-info.svg" className="img-fluid" />
										<p>{"Under-performing (if overall maturity > 2, use case maturity <= 15%)"}</p>
									</li> */}
									<li>
										<img src="./assets/images/icon-info.svg" className="img-fluid" />
										<p>{getUseCaseText()}</p>
									</li>
								
								
								
							</ul>
							)}
							<div className="row dimension-graph">
								<div className={resultObj[props.auth.authData.useCaseStage] != undefined ? "col-md-6" : "col-md-12"}>
									<div className="dimension-graph-tile">
										<SpiderChart/>
									</div>
								</div>
								{resultObj[props.auth.authData.useCaseStage] != undefined && (
									<div className="col-md-6">
										<div className="dimension-graph-tile">
											<BlockChart value={result}/>
										</div>
									</div>
								)}
							</div>
							<Button className="dimension-download-btn" loading={loadingStatus} onClick={() => generatePdf()}> <a href={apiURL + 'survey/recommendationReport?access_token=' + localStorage.token} >{buttonText}</a></Button>
		            	</div>
		            </div>
		        </div>
	        </React.Fragment>
        </div>
    )
}
const mapStateToProps=(state)=>{
	return{
		auth:state.auth,
		userSectionData: state.userSectionData
	}
}
const mapDispatchToProps = (dispatch)=>{
	return bindActionCreators({ getUserSectionData: getUserSectionData},dispatch)
}
export default connect(mapStateToProps,mapDispatchToProps)(FinalResult)