import axios from 'axios'
import apiURL from '../env'
import { startRequest, endRequest, endRequestError } from './authLoader'
import * as type from '../Actions/constant'
export function getUrlData(url) {
    return dispatch => {
        dispatch(startRequest())
        axios.get(apiURL+'sponsor/getSponsorContent?sponsor='+url)
            .then((response) => {
              if(response.data.statusCode!=404){
                  dispatch({
                      type: type.GET_URL_DATA,
                      payload: response.data
                  })
              }else{
                  window.location.href="/#/"
              }
                
            })
            .then((res) => {
                dispatch(endRequest())
            })
            .catch((error) => {
                console.log(error)
            })
    }
}