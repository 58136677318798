import React, { useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {getPage} from '../Actions/getPage'
import {Link,useLocation,Redirect} from 'react-router-dom'
import apiURL from '../env'
import { getAuthentication} from '../Actions/loginCheck'
import { Popconfirm, message } from 'antd';
import axios from 'axios'
import { LogoutOutlined } from '@ant-design/icons';
const HomeHeader = (props) => {
	let location = useLocation();
	const [redirectPath, setState] = useState("")
	const clickYes=()=>{
		axios({
			method: 'POST',
			url: apiURL + 'leads/logout'
		}).then((res) => {
			localStorage.clear()
			props.getAuthentication()
			setState("/")
		})
	}
	const clickNo=()=>{

	}
    return (
	    <div>
	    	<header className="homeHeader navbar fixed-top">
		    	<div className="container-fluid">
		        	<div className="header-logo">
						<a href="" className="idc-logo"><img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/idc-logo.svg" className="img-fluid"/></a>
		        	</div>
		        	<div className="header-menu d-none d-md-flex">
						{props.auth.authStatus?(
							((props.auth.authStatus && (props.auth.authData.sponsorId != undefined && props.auth.authData.sponsorId!=""))?(
							<ul>
								<li><Link to="/">Home</Link></li>
								{/* <li><Link to="/howItWorks">How It Works</Link></li> */}
								<li><Link to="/dashboard">Dashboard</Link></li>
								<li>{props.auth.authData.first_name} |<Link onClick={clickYes}>Logout</Link>
								</li>
							</ul>
						):(
								<ul>
									<li><Link to="/">Home</Link></li>
									<li><Link to="/howItWorks">How It Works</Link></li>
									<li>{props.auth.authData.first_name} |<Link onClick={clickYes}>Logout</Link></li>
								</ul>
						))
						):(
							<ul>
							<li className="active"><Link to="/">Home</Link></li>
							<li><Link to="/howItWorks">How It Works</Link></li>
							<li><Link to="/login">Login</Link></li>
						</ul>
						)}
						{(Object.values(props.urlData).length) > 0 && (
							<div className="header-logo">
								<a href="" className="sponsor-logo"><img src={props.urlData.logo_url} className="img-fluid" /></a>
							</div>
						)}
		        		
		        	</div>
		        	<div className="d-block d-md-none mob-menu">
				        <button className="js-menu menu" type="button"><span className="bar"></span></button>
				        <nav className="navslide">
							{props.auth.authStatus ? (
								((props.auth.authStatus && (props.auth.authData.sponsorId != undefined && props.auth.authData.sponsorId != "")) ? (
									<ul>
										<li><Link to="/">Home</Link></li>
										{/* <li><Link to="/howItWorks">How It Works</Link></li> */}
										<li><Link to="/dashboard">Dashboard</Link></li>
										<li>{props.auth.authData.first_name} | <Popconfirm
											icon={<LogoutOutlined style={{ color: 'red' }} />}
											title="Are you sure you want to Logout?"
											onConfirm={clickYes}
											okText="Yes"
											cancelText="No"
										>
											<a>Logout</a>
										</Popconfirm></li>
									</ul>
								) : (
									<ul>
										<li><Link to="/">Home</Link></li>
										<li><Link to="/howItWorks">How It Works</Link></li>
											<li>{props.auth.authData.first_name} | <Popconfirm
												icon={<LogoutOutlined style={{ color: 'red' }} />}
												title="Are you sure you want to Logout?"
												onConfirm={clickYes}
												okText="Yes"
												cancelText="No"
											>
												<a>Logout</a>
											</Popconfirm></li>
									</ul>
								))
							) : (
								<ul>
									<li><Link to="/">Home</Link></li>
									<li><Link to="/howItWorks">How It Works</Link></li>
									<li><Link to="/login">Login</Link></li>
								</ul>
							)}
				        </nav>
				    </div>
		        </div>
				{redirectPath!=""&&<Redirect to={redirectPath}/>}
	        </header>
	        
	    </div>
    )
}
const mapStateToProps=(state)=>{
	return {auth:state.auth,urlData:state.urlData}
}
const mapDispatchToProps=(dispatch)=>{
	return bindActionCreators({ getAuthentication:getAuthentication,getPage: getPage},dispatch)
}
export default connect(mapStateToProps,mapDispatchToProps)(HomeHeader)