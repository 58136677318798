import * as type from './constant'
export function startRequest() {
    return {
        type: type.REQUEST_START
    }
}
export function endRequest() {
    return {
        type: type.REQUEST_END
    }
}

export function endRequestError() {
    return {
        type: type.REQUEST_ERROR
    }
}