const initialState = {
    loading: false
}
export default function (state = initialState, action) {
    switch (action.type) {
        case "USER_REQUEST_START": return Object.assign({}, state, {
            loading: true
        });
        case "USER_REQUEST_END": return Object.assign({}, state, {
            loading: false
        });
        case "USER_REQUEST_ERROR": return Object.assign({}, state, {
            loading: false
        });
        default: return state
    }
}