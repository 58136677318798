import axios from 'axios'
import apiURL from '../env'
import * as type from '../Actions/constant'
import {startRequest,endRequest,endRequestError} from './authLoader';
export function getSponsors() {
    return dispatch => {
        dispatch(startRequest())
        axios.get(apiURL+'sponsor?filter[fields][sponsor_name]='+true+'&filter[fields][logo_url]='+true+'&filter[fields][id]='+true+'order="createdAt DESC"&access_token='+localStorage.token).then((res)=>{
            dispatch({
                type: type.GET_SPONSORS,
                payload:res.data
            })

        }).then(function (res) {
            dispatch(endRequest())
        })
        .catch(function (error) {
            console.log(error)
        })
    }
}

