export const GET_AUTH ="GET_AUTH"
export const REQUEST_START = 'REQUEST_START'
export const REQUEST_END = 'REQUEST_END'
export const REQUEST_ERROR = 'REQUEST_ERROR'
export const GET_CURRENT_PAGE ="GET_CURRENT_PAGE"
export const GET_URL_DATA ="GET_URL_DATA"
export const GET_SPONSORS ="GET_SPONSORS"
export const GET_USER_DATA = "GET_USER_DATA"
export const USER_REQUEST_START = 'USER_REQUEST_START'
export const USER_REQUEST_END = 'USER_REQUEST_END'
export const USER_REQUEST_ERROR = 'USER_REQUEST_ERROR'
export const QUESTION_REQUEST_START ='QUESTION_REQUEST_START'
export const QUESTION_REQUEST_END ='QUESTION_REQUEST_END'
export const QUESTION_REQUEST_ERROR ='QUESTION_REQUEST_ERROR'
export const TOGGLE_MODAL ="TOGGLE_MODAL"
export const TRACK_TIME = "TRACK_TIME"
