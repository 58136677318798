const initialState = {
    data: []
}
export default function (state = initialState, action) {
    switch (action.type) {
        case 'GET_LEAD_SURVEY':
            return {
                ...state,
                data: action.payload
            }
            break;
    }
    return state
}