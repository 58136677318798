import React from 'react';
import MainHeader from '../../Header/main';
import SectionHeader from '../../Header/sectionHeader';
import { Link, Redirect} from 'react-router-dom'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
const Result=(props)=>{
	let resultObj = {
		"Apprentice": "an APPRENTICE",
		"Practitioner": "a PRACTITIONER",
		"Beginner": "a BEGINNER",
		"Leader": "a LEADER",
		"Expert": "an EXPERT"
	}
	const getResult = () => {
		let result = props.auth && props.auth.authData.processStage
		return resultObj[result]
	}
	const nextSection = (prop) => {
		let next = props.userSectionData.data && props.userSectionData.data.find(rec => (rec.isCompleted == false && rec.isDisabled == false))
		if (next) {
			return (
				<>
					<p>Proceed to the {next && next.categoryName}</p>
					<Link className="next-assmnt-btn" to="/dashboard">Next Assessment</Link>
				</>)
		} else {
			return (<Link to="/final-result" className="next-assmnt-btn">Download your detailed report here</Link>)
		}
	}
    return (
    	<React.Fragment>
			<MainHeader/>
			<SectionHeader id="Process"/>
		        <div className="content-wrap content-wrap-with-sectionHeader">
		            <div className="container">
		            	<div className="org-result">
		            		<div className="org-result-cnt">
								<img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-handshake.svg" className="img-fluid"/>
		            			<h4>Congratulations on completing your dimensional assessment.</h4>
		            			<label>Based on your response, you are {getResult()} on the Process dimension.</label>
								<b>Process challenges are often related to the need for (and the lack of) awareness and discipline, such as the tendency to view data initiatives as intangible and one-off, divorced from the reality on the ground. The leadership team needs to regularly communicate the importance of data and its lifecycle, in their decisions and behaviors. They need to lubricate the frictions of cross-team collaboration and setup the necessary metrics and operations to ensure consistency under the guidance of agile but disciplined methodologies.</b>
		            		</div>
		            		<div className="org-result-cnt border-0">
								<p>{nextSection()}</p>
		            		</div>
		            	</div>
		            </div>
		        </div>
	        </React.Fragment>
    )
}
function mapStateToProps(state) {
	return { userSectionData: state.userSectionData, auth: state.auth}
}
function matchDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch)
}
export default connect(mapStateToProps, matchDispatchToProps)(Result)