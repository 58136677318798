const initialState = {
    authStatus: false,
    loaded: false,
    authType: "AUTH_FAILED",
    authData: {},
    surveyData: [], 
    surveyUpdates:[]
}
export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_AUTH":
            return {
                ...state,
                authStatus: action.payload.status,
                loaded: action.payload.loaded,
                authType: action.payload.authType,
                authData: action.payload.authData,
                surveyDetails: action.payload.surveyDetails,
                surveyData: action.payload.surveyData,
                page: action.payload.page,
                surveyUpdates: action.payload.surveyUpdates
            }
            break;
    }
    return state
}