let fullGlossary={
    "data initiatives":"The organizational use of data tools to collect, tabulate, visualize and analyze data to solve problems, reveal insights and support decision makings.",
    "Data visualization":"The use of off-the-shelf tools to view data patterns and trends, often by end users. Examples of data visualization tools are Tableau, Excel, QlikView, etc., and embedded reports in ERP/CRM applications not requiring querying or coding.",
    "data visualization": "The use of off-the-shelf tools to view data patterns and trends, often by end users. Examples of data visualization tools are Tableau, Excel, QlikView, etc., and embedded reports in ERP/CRM applications not requiring querying or coding.",
    "data analytics":"The interactive use of statistical tools and techniques to identify patterns in data, explore trends and help businesses make decisions. Examples of data analytics tools are SAS, QlikSense, PowerBI, MiniTab, MatLab, SPSS, etc.",
    "data analytics tools":"The interactive use of statistical tools and techniques to identify patterns in data, explore trends and help businesses make decisions. Examples of data analytics tools are SAS, QlikSense, PowerBI, MiniTab, MatLab, SPSS, etc.",
    "data science":"The use of advanced statistical and machine learning tools and techniques to build models to extract knowledge and insights. Examples of data science tools are Jupyter Notebooks, Scikit-Learn, Pandas, Apache Spark, DataRobot, BigML, Keras, etc.",
    "data science tools": "The use of advanced statistical and machine learning tools and techniques to build models to extract knowledge and insights. Examples of data science tools are Jupyter Notebooks, Scikit-Learn, Pandas, Apache Spark, DataRobot, BigML, Keras, etc.",
    "Data science": "The use of advanced statistical and machine learning tools and techniques to build models to extract knowledge and insights. Examples of data science tools are Jupyter Notebooks, Scikit-Learn, Pandas, Apache Spark, DataRobot, BigML, Keras, etc.",
    "data monetization":"The use of data to enable new products, services and experiences; or the provision of data as a service to business partners.",
    "data engineering":"The work of data collection, preparation and management, including the design and management of data flows.",
    "data strategy":"Business plans to leverage data, and data enabled products, to gain a competitive advantage and support enterprise goals.",
    "data risk":"The potential business, monetary or reputational losses due to poor protection, or governance or management of data.",
    "data literacy":"The ability to read, interpret, work with, argue with and act upon data and data products such as analytics reports, interactive visualizations and data-driven insights.",
    "data operations":"Also known as DataOps, it stands for a process-oriented methodology to align all necessary roles to manage data flows, deliver data analytics and develop data-powered applications efficiently and credibly.",
    "lineage and traceability":"Whether and how the stages from the moment the data is captured, accessed, edited and consumed to its eventual archival and/or deletion at the end of its usefulness are managed, traced and visualized.",
    "lineage": "Whether and how the stages from the moment the data is captured, accessed, edited and consumed to its eventual archival and/or deletion at the end of its usefulness are managed, traced and visualized.",
    "Data Analytics Solutions":"Applications and/or reports catering to specific use cases that are made available/accessible for non-technical users.",
    "Data governance":"Data governance refers to the policies, processes and practices of managing the availability, usability, consistency, integrity and security of the data in enterprise systems.",
    "Data architecture":"Data architecture specifies how data is collected, stored, transformed, distributed and used. It includes semantic, logical and physical data models and how they are reflected in actual databases.",
    "data architecture": "Data architecture specifies how data is collected, stored, transformed, distributed and used. It includes semantic, logical and physical data models and how they are reflected in actual databases.",
    "data platform":"Platform solutions to take care of end to end non-transactional data management needs including ingestion, streaming, federation, preparation, integrity, lifecycle, access rights etc. It typically includes the likes of data pipelines, data warehouses, data lakes and data marts.",
    "data federation and integration":"Data federation is to create a virtual database that aggregates data from different sources, providing a single source of data for front-end applications.",
    "data bias":"Data bias is when the available data is not representative of the population or the subject of study. It can be attributed to either a lack of data quantity, acquired by the data collectors or inherent of the data producers.",
    "Data Professionals":"Data analyst, data engineers, data scientist, data stewards, AI practitioners, etc.",
    "data professionals":"Data analyst, data engineers, data scientist, data stewards, AI practitioners, etc.",
    "discoverability":"Whether and how the right data can be found in a search of the information system.",
    "accessibility":"Whether and how the users can get access to the right data securely and easily.",
    "interoperability":"Whether and how data in multiple systems can transfer and exchange accurately based on a common reference model.",
    "scalability":"Whether and how data workloads (such as access and compute) of very different scales can be handled with ease.",
    "KPI":"Key performance indicator (KPI) is a value used to measure the effectiveness of an individual, team or company.",
    "CoE":"Center of Excellence (COE) is a team, a shared facility or an entity that provides leadership, best practices, research, support and/or training for a focus area.",
    "CI/CD":"Continuous Integration/Continuous Deployment (CI/CD) forms the backbone of DevOps by bridging the gaps between development and operation activities and teams.",
    "CI/CD practices": "Continuous Integration/Continuous Deployment (CI/CD) forms the backbone of DevOps by bridging the gaps between development and operation activities and teams.",
    "descriptive analysis":"A quantitative description or summary about the main features of data.",
    "predictive analysis":"The prediction of future events/states by analyzing historical or real-time data.",
    "prescriptive analysis":"The suggestion of decisions and actions by analyzing historical or real-time data.",
    "machine learning":"The study of using a set of algorithms to build non-deterministic models that can make suggestions, predictions or decisions. It is a subset of artificial intelligence.",
    "deep learning":"The study of using a set of neural network algorithms to build non-deterministic models that can make suggestions, predictions or decisions. It is a subset of machine learning.",
    "AI(Artificial Intelligence)":"The study, or the application, of computer algorithms that are capable of understanding context or performing cognitive tasks without being explicitly instructed to.",
    "use cases":"A software solution or application to consistently achieve a well-defined goal, for example, the one-off use of Excel Worksheet is not considered a use case.",
    "Organization Dimension":"Organization dimension is where changes start. Questions in this dimension evaluate the organization's data strategy, its orientation, and the organizational support received including execution status, ownership and actual scope when drilled down to progressive aspects consisted of (1) analytics, (2) data science and (3) data monetization.",
    "People Dimension":"People dimension is where changes receive rapports. Questions in this dimension are to evaluate people factors including (1) data skills: whether they are systematically managed, developed and trained, (2) data roles: whether they are in place and sufficient to support the data initiatives, and (3) whether there is pro-data work culture to accelerate change both from bottom-up and top-down.",
    "Process Dimension":"Process dimension is where changes are disciplined. Questions in this dimension are to evaluate whether data of different types are measured to reflect different aspects of data management requirements, whether data operations are disciplined, whether data initiatives are measurable/outcome-based and whether business/IT processes changes are aligned with the data initiatives.",
    "Technology Dimension":"Technology dimension is where changes are facilitated. Questions in this dimension are to evaluate whether advanced technology solutions are in place to cover 3 layers of needs: (1) data platform needs, (2) analytics tools needs, (3) application development needs, and how they have been reflected in the scope and the workload of data use cases.",
    "Data Dimension":"Data dimension is where changes are fueled. Questions of this dimension are to evaluate whether different aspects of data as the raw material for such initiatives are systematically addressed, and how extensively they have been used.",
    "Use Case Dimension":"Use case dimension is where changes are materialized. Questions in this dimension are to correlated evaluation results of all above dimensions and cross check with the actual use cases that have been implemented and operationalized."
}
export default fullGlossary