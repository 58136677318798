import React,{Component} from 'react'

import KeyboardEventHandler from 'react-keyboard-event-handler';


class Test extends Component{
    constructor(props){
        super(props)
    }
    componentWillReceiveProps(next){
        console.log("next")
    }
    render(){
        return(
            <div>
                hihg
                
            </div>
        )
    }
}
export default Test
