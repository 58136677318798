import React,{useEffect} from 'react'
import { connect } from 'react-redux'
var Highcharts = require('highcharts');
const BlockChart = (props) => {
    useEffect(() => {
        Highcharts.chart('blockChart', {
            chart: {
                type: 'scatter',
                plotBackgroundImage: 'https://daaistore.s3-ap-southeast-1.amazonaws.com/interaction.jpg'
            },
            credits: {
                enabled: false
            },
            title: {
                text: 'Dimensional Interaction'
            },
            xAxis: {
                min: 0,
                max: 4,
                title: {
                    text: "Maturity of Capabilities"
                }
            },
            plotOptions: {
                scatter: {
                    marker: {
                        symbol: 'url(https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-user.svg)',
                        radius: 7,
                        fillColor: '#8BC34A',
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)'
                            }
                        }
                    },
                    tooltip: {
                        headerFormat: '<b>{point.name}</b><br>',
                        pointFormat: 'Maturity of Capabilities:{point.x} Application to Business Use:{point.y}% '
                    }
                },
                plotOptions: {
                    series: {
                        showInLegend:false,
                        color: '#303030'
                    }
                },
            },
            yAxis: {
                min: 0,
                max: 100,
                title: {
                    text: "Application to Business Use"
                },
                gridLineColor: '#ffffff00',
            },
            series: [{
                name:"",
                data: [[props.value,props.auth.authData.useCaseResult]],
            }]
        });
    }, [])
    return (<div id="blockChart">
    </div>)
}
const mapStateToProps = (state) => {
    return { auth: state.auth }
}

export default connect(mapStateToProps)(BlockChart)