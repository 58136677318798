import React from 'react';
import MainHeader from '../../Header/main';
import SectionHeader from '../../Header/sectionHeader';
import { Link, Redirect} from 'react-router-dom'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
const Result=(props)=>{
	let resultObj = {
		"Apprentice": "an APPRENTICE",
		"Practitioner": "a PRACTITIONER",
		"Beginner": "a BEGINNER",
		"Leader": "a LEADER",
		"Expert": "an EXPERT"
	}
	const getResult = () => {
		let result = props.auth && props.auth.authData.useCaseStage
		return resultObj[result]
	}
	const nextSection = (prop) => {
		let next = props.userSectionData.data && props.userSectionData.data.find(rec => (rec.isCompleted == false && rec.isDisabled == false))
		if (next) {
			return (
				<>
					<p>Proceed to the {next && next.categoryName}</p>
					<Link className="next-assmnt-btn" to="/dashboard">Next Assessment</Link>
				</>)
		} else {
			return (<Link to="/final-result" className="next-assmnt-btn">Download your detailed report here</Link>)
		}
	}
    return (
    	<React.Fragment>
			<MainHeader/>
			<SectionHeader id="Use Case"/>
		        <div className="content-wrap content-wrap-with-sectionHeader">
		            <div className="container">
		            	<div className="org-result">
		            		<div className="org-result-cnt">
								<img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-handshake.svg" className="img-fluid"/>
		            			<h4>Congratulations on completing your dimensional assessment.</h4>
		            			<label>Based on your response, you are {getResult()} on the Use Case dimension.</label>
								<b>Use case implemented serves as a cross checkpoint for companies to review their investment plan and discover possible misalignment with their DAAI readiness capabilities.</b>
		            		</div>
		            		<div className="org-result-cnt border-0">
								<p>{nextSection()}</p>
		            		</div>
		            	</div>
		            </div>
		        </div>
	        </React.Fragment>
    )
}
function mapStateToProps(state) {
	return { userSectionData: state.userSectionData, auth: state.auth}
}
function matchDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch)
}
export default connect(mapStateToProps, matchDispatchToProps)(Result)