import React,{useEffect,useState} from 'react'
import {Select, AutoComplete } from 'antd';
import MainHeader from '../Header/main'
import {getSponsors} from '../Actions/getSponsors'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios'
import apiURL from '../env'
import {Redirect} from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons';
import { getAuthentication} from '../Actions/loginCheck'
const Option = Select.Option;

const Sponsor=(props)=>{
    const [fullState, setState] = useState({
        sponsor: "",
        selectedSponsorId: "",
        selectSponsor: "",
        selectedType: Object.values(props.urlData).length > 0 ? "text" : "",
        errorMsg: "",
        visibleStatus: true,
        isRedirect: false,
        redirectPath: "",
        loading: false,
    })
    const [sponsorNameArray, setSponsorArray] = useState([])
   
    useEffect(() => {
        props.getSponsors()
        if (Object.values(props.urlData).length > 0) {
            axios({
                method: "GET",
                url: apiURL + "sponsor/getCouponCodes?sponsorId=" + props.urlData._id
            }).then(res => {
                setState({ ...fullState, sponsor: res.data.length?res.data[0].coupon_code:""})
            })
        }
    }, [])
    const getSponsorName=(value, key)=>{
     
        if (value != "") {
            axios({
                method: "GET",
                url: apiURL + "sponsor/listSponsorNames?search=" + value
            }).then((res) => {
                setSponsorArray(res.data.data)
            })
        } else{
            setSponsorArray([])
        }
           
    }
   const loginClicked=()=> {
        if ((fullState.selectedType != "") && (fullState.sponsor || fullState.selectedSponsorId)) {
            if (fullState.selectedType == 'text') {
                setState({ loading: true })
                axios({
                    method: "PATCH",
                    url: apiURL + 'leads/linkSponsorToLead/' + props.auth.id + '?access_token=' + localStorage.token,
                    data: {
                        "fromCoupon": true,
                        "sponsorId": (Object.values(props.urlData).length > 0) ? props.urlData._id : "",
                        "couponCode": fullState.sponsor,
                        "fromUrl": (Object.values(props.urlData).length > 0) ? true : false
                    }
                }).then((res) => {
                    setState(Object.assign({}, fullState, { loading: false}))
                    props.getAuthentication()
                    setState(Object.assign({}, fullState, { isRedirect: true, redirectPath: "/dashboard"}))
                }).catch((err) => {
                    console.log("error",err)
                    setState(Object.assign({}, fullState, { loading: false }))
                    if (err.response.data.error.message) {
                        setState(Object.assign({}, fullState, { errorMsg: err.response.data.error.message }))
                    }
                })
            }
            else if (fullState.selectedType == 'dropdown') {
                setState({ loading: true })
                axios({
                    method: "PATCH",
                    url: apiURL + 'leads/linkSponsorToLead/' + props.auth.id + '?access_token=' + localStorage.token,
                    data: {
                        "fromCoupon": false,
                        "sponsorId": fullState.selectedSponsorId,
                        "couponCode": ""
                    }
                }).then((res) => {
                    setState(Object.assign({}, fullState, { loading: false }))
                    props.getAuthentication()
                    setState({ ...fullState, isRedirect: true, redirectPath: "/dashboard" })
                }).catch((err) => {
                    setState(Object.assign({}, fullState, { loading: false }))
                    if (err.response.data.error.message) {
                        setState(Object.assign({}, fullState, { errorMsg: err.response.data.error.message }))
                    }
                })
            }
        }
    }
    return (
        <React.Fragment>
        <MainHeader/>
            <div className="content-wrap login-wrap overlay" style={{ backgroundImage: "url('https://daaistore.s3-ap-southeast-1.amazonaws.com/bg-login.jpg')"}}>
            <div className="container">
                <div className="login-form">
                    {props.auth && (
                        <h2>Hi, {props.auth.first_name}{" "}{props.auth.last_name}</h2>
                    )}
                    {Object.values(props.urlData).length > 0 ? (
                        <React.Fragment>
                         <span className="sponsor-sub-text">Please use your <b>SPONSOR CODE</b> to Start</span>
                        <div className="form-group">
                            <input type="text" name="Sponsorcode" placeholder="Sponsor Code" className="form-control" onChange={(e) => setState({ sponsor: e.target.value, selectedSponsorId: "", selectSponsor: "", selectedType: "text", errorMsg: "" })} value={fullState.sponsor}/>
                            {fullState.selectedType == "text" && (
                                <span className="login-error-msg">{fullState.errorMsg}</span>
                            )}
                        </div>
                       
                        </React.Fragment>
                    ):(
                        <React.Fragment>
                            <span className="sponsor-sub-text">Please use your <b>SPONSOR CODE</b> to Start</span>
                            <div className="form-group">
                                <input type="text" name="Sponsorcode" placeholder="Sponsor Code" className="form-control" onChange={(e) => setState({ sponsor: e.target.value, selectedSponsorId: "", selectSponsor: "", selectedType: "text", errorMsg: "" })} value={fullState.sponsor}/>
                                {fullState.selectedType == "text" && ( 
                                <span className="login-error-msg">{fullState.errorMsg}</span>
                            )} 
                            </div>
                            
                            <span className="sponsor-sub-text text-center">OR</span>
                            <span className="login-sub-text text-center">If you don't have a Sponsor, please enter the name of the Company you wish to be sponsored by. You will be notified if your request has been accepted.</span>
                            <div className="form-group choose-sponsor">
                                <AutoComplete
                                        value={fullState.selectSponsor}
                                        allowClear={true}
                                        onChange={(e, value) => {setState({ selectedSponsorId: (value.key==undefined)?e:value.key, selectSponsor: (e != undefined ? (value.label && value.label.trim()) : ""), sponsor: "", selectedType: "dropdown", errorMsg: "" }) }}
                                        onSearch={(e, key) => getSponsorName(e, key)}
                                        allowClear={true}
                                        placeholder="Start typing the name of your preferred sponsor"
                                >
                                    {sponsorNameArray&&sponsorNameArray.map((data) => (
                                        <Option className="test-test" label={data.sponsor_name} key={data._id} value={data.sponsor_name}>
                                            {data.sponsor_name}
                                   </Option>
                                  ))}
                                
                                </AutoComplete> 
                                {fullState.selectedType == "dropdown" && (
                                    <span className="login-error-msg">{fullState.errorMsg}</span>
                                )}
                            </div>
                            
                        </React.Fragment>
                    )}
                        <button className="login-submit-btn btn w-100" type="submit" onClick={() => loginClicked()}>Begin Survey {fullState.loading && <LoadingOutlined className="btn-loader"/>}</button>
                </div>
            </div>
        </div>
            {fullState.isRedirect ? <Redirect to={fullState.redirectPath} /> : null}
        </React.Fragment>
    )
}
const mapStateToProps = (state) => {

    return {
        auth: state.auth.authData,
        sponsorData: state.getSponsors,
        urlData: state.urlData
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getAuthentication: getAuthentication, getSponsors: getSponsors }, dispatch)
}
export default connect(mapStateToProps,mapDispatchToProps)(Sponsor)