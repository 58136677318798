const initialState = {}
export default function (state = initialState, action) {
    switch (action.type) {
        case 'TRACK_TIME':
            return {
                ...state, ...action.payload
            }
        default: return state
    }

}