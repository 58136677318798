import React,{useEffect} from 'react'
import {connect} from 'react-redux'
var Highcharts = require('highcharts');
require('highcharts/highcharts-more')(Highcharts)
const SpiderChart=(props)=>{
    let arr = [props.auth.authData.orgResult, props.auth.authData.peopleResult, props.auth.authData.processResult, props.auth.authData.technologyResult, props.auth.authData.dataResult,props.auth.authData.useCaseResult]
    console.log("arr",arr)
    let valueArr = arr.map(item=>(item)?item:0)
    useEffect(() => {
        Highcharts.chart('container', {
            chart: {
                polar: true,
                type: 'line',
            },
            accessibility: {
                description: ''
            },
            title: {
                text: 'Dimensional Performance',
                x: -80
            },
            pane: {
                size: '80%'
            },
            xAxis: {
                categories: ['Organization Dimension', 'People Dimension', 'Processes Dimension', 'Technology Dimension',
                    'Data Dimension', 'Use Case Dimension'],
                tickmarkPlacement: 'on',
                lineWidth: 0
            },
            yAxis: {
                gridLineInterpolation: 'polygon',
                lineWidth: 0,
                min: 0,
                labels: {
                    enabled: false
                }
            },
            tooltip: {
                enabled: true,
                formatter: function () {
                    return this.points.reduce(function (s, point) {
                        return s + '<br/>' + point.series.name + ': ' +
                            point.y + 'm';
                    }, '<b>' + this.x + '</b>');
                },
                shared: true
            },
            legend: {
                align: 'right',
                verticalAlign: 'middle',
                layout: 'vertical'
            },
            series: [{
                name: 'Your Organization',
                data: valueArr,
                pointPlacement: 'on',
                color: "#0000FF",
            }, {
                name: 'Asia/Pacific Average',
                data: [20, 20, 20, 20, 20, 20],
                pointPlacement: 'on',
                color: "#FFA500",
               
            }],
            credits: {
                enabled: false
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            layout: 'horizontal'
                        },
                        pane: {
                            size: '90%'
                        }
                    }
                }]
            }
        });
    },[])
    return (
        <div id="container"></div>
   )
}
const mapStateToProps=(state)=>{
    return {auth:state.auth}
}

export default connect(mapStateToProps)(SpiderChart)