import React, { useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators} from 'redux'
import MainHeader from '../Header/main';
import { getUserSectionData} from '../Actions/getUserData'
import { Skeleton,Button } from 'antd';
import {Link, Redirect} from 'react-router-dom'
import axios from 'axios'
import apiURL from '../env'
const Dashboard=(props)=>{
	useEffect(() => {
		props.getUserSectionData()
	}, [])
	const [buttonText, setButton] = useState("Download Report")
	const [loadingStatus, setLoading] = useState(false)

	const [state, setstate] = useState({
		"RedirectPath":"",
		"Organization Dimension":{
			sectionName:"Organization",
			image:"https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-organization.svg",
			completedClass:"survey-item completed color-orgz",
			inProgressClass:"survey-item in-progress color-orgz",
			path:"organization"
		},
		"People Dimension": {
			sectionName: "People",
			image: "https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-people.svg",
			completedClass: "survey-item completed color-people",
			inProgressClass: "survey-item in-progress color-people",
			path:"people"

		},
		"Process Dimension": {
			sectionName: "Process",
			image: "https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-process.svg",
			completedClass: "survey-item completed color-process",
			inProgressClass: "survey-item in-progress color-process",
			path: "process"

		},
		"Technology Dimension": {
			sectionName: "Technology",
			image: "https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-technology.svg",
			completedClass: "survey-item completed color-technology",
			inProgressClass: "survey-item in-progress color-technology",
			path: "technology"

		},
		"Data Dimension": {
			sectionName: "Data",
			image: "https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-data.svg",
			completedClass: "survey-item completed color-data",
			inProgressClass: "survey-item in-progress color-data",
			path: "data"

		},
		"Use Case Dimension": {
			sectionName: "Use Case",
			image: "https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-use-case.svg",
			completedClass: "survey-item completed color-usecase",
			inProgressClass: "survey-item in-progress color-usecase",
			path: "use-case"

		}
	})
	const getStatement=()=>{
		let value=props.userSectionData.data && props.userSectionData.data.find(rec => (rec.isCompleted == false && rec.isDisabled == false))
		let orgResultCase = props.userSectionData.data && props.userSectionData.data.find(rec => (rec.categoryId=="602cbde7660a890265198806"))
		if (orgResultCase&&orgResultCase.isCompleted==false){
			return (<p>Please get started with the Organization dimension. Some sections may be disabled based on your responses in the Organization dimension.</p>)
		} else{
			let result=props.userSectionData.data && props.userSectionData.data.filter(rec => (rec.isCompleted == true))
			if(result.length>=3){
				return(<>
					<p>A report is now available for you to download based on the completed dimensions. You may download it now, or proceed to complete the enabled dimensions to get the complete report.</p>
					<Button loading={loadingStatus} className="btn report-download-btn" onClick={() => generatePdf()}> <a href={apiURL + 'survey/recommendationReport?access_token=' + localStorage.token} >{buttonText}</a></Button>
				</>)
			}
		}
	}
	const generatePdf=()=>{
		setLoading(true)
		setButton("Your report is being prepared, please wait.")
		axios({
			method: "GET",
			url: apiURL + 'survey/recommendationReport?access_token=' + localStorage.token
		}).then((res) => {
			setLoading(false)
			setButton("Download Report")
		}).catch((err)=>{
			setLoading(false)
			setButton("Download Report")
		})
	}
    return (
    	<React.Fragment>
			<MainHeader/>
	        <div className="content-wrap dashboard-wrap">
		        <div className="container">
					<h2>Welcome <b>{props.auth.authData && props.auth.authData.first_name + " " +props.auth.authData.last_name}</b></h2>
					{getStatement()}
					
				
				
					<div className="survey-list">
		        		<div className="row">
							{props.userLoader.loading?(
								[...Array(6).keys()].map((rec,index)=>{
									return(
										<div className="col-6 col-md-4 col-box" key={index}>
											<div className="survey-item pl-4 pr-4">
												<Skeleton.Avatar active={"active"} size={"default"} shape={"circle"} className="img-fluid survey-icon" />
												<Skeleton paragraph={false} />
												<Skeleton paragraph={false} />
											</div>
										</div>
									)
								})
							) : (!props.userLoader.loading && props.userSectionData.data&&props.userSectionData.data.map(rec=>{
								return(
									<div className="col-6 col-md-4 col-box" key={rec.categoryId}>
										<div className={rec.isDisabled ? "survey-item " : ((rec.isCompleted) ? state[rec.categoryName].completedClass : state[rec.categoryName].inProgressClass)}>
											<img src={state[rec.categoryName].image} className="img-fluid survey-icon"/>
											<label>{state[rec.categoryName].sectionName}</label>
											{(rec.isCompleted)?(
												<a style={{"textDecoration":"none"}}>COMPLETED</a>
											):(
												<a onClick={() => { !rec.isDisabled && setstate(Object.assign({}, state, { RedirectPath: state[rec.categoryName].path })) }}>{rec.lastAnswerdQuestionId == "" ? "START" : ((!rec.isDisabled && rec.isCompleted) && rec.lastAnswerdQuestionId != "") ? "COMPLETED" : "RESUME"}</a>
											)}
											<img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/locked.svg" className="img-fluid icon-lock"/>
											<img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/completed.svg" className="img-fluid icon-complete"/>
										</div>
									</div>
								)
							}))}
		        		</div>
		        	</div>
		        </div>
				{state.RedirectPath!=""&&(
					<Redirect to={state.RedirectPath} />
				)}
	        </div>
        </React.Fragment>
        
    )
}
const mapStateToProps=(state)=>{

	return {
			authLoader:state.authLoader,
			auth:state.auth,
			userSectionData: state.userSectionData,
			userLoader: state.userLoader
	}
}
const mapDispatchToProps=(dispatch)=>{
	return bindActionCreators({ getUserSectionData: getUserSectionData},dispatch)
}
export default connect(mapStateToProps,mapDispatchToProps)(Dashboard)