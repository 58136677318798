import axios from 'axios'
import { startRequest, endRequest, endRequestError} from './authLoader'
import * as type from './constant'
import apiURL from '../env'
export function getAuthentication(){
    var token = localStorage.token
    return dispatch => {
        dispatch(startRequest())
        axios.get(apiURL + 'leads/isLogedIn?access_token=' + token).then(function (res) {
            if (res.data.data.loggedStatus) {
                axios({
                    method: "POST",
                    url: apiURL + 'timeStamps/trackData?access_token=' + localStorage.token,
                    params: {
                        event: 'isLogedIn',
                    }
                }).then((res) => {
                    dispatch({
                        type: type.TRACK_TIME,
                        payload: res

                    })
                }).catch(function (error) {
                    console.log(error)
                })
                axios.defaults.headers.common['Authorization'] = token;
                dispatch({
                    type: type.GET_AUTH,
                    payload: { status: true, loaded: true, authType: "AUTH_SUCCESS", authData: res.data.data.leadData, surveyDetails: res.data.data.surveyDetails, surveyUpdates: res.data.data.surveyUpdates}
                })
            }
            else {
                dispatch({
                    type: type.GET_AUTH,
                    payload: { status: false, loaded: true, authType: "AUTH_FAILED" }
                })
            }
            dispatch(endRequest())
        }).catch((err) => {
            dispatch({
                    type: type.GET_AUTH,
                    payload: { status: false, loaded: true, authType: "AUTH_FAILED" }
                })
            dispatch(endRequestError())
            console.log("error", err)
        })
    }
}