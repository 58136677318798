import React from 'react';
import {connect} from 'react-redux'
import toggleModal from '../Actions/togglePop'
import { bindActionCreators } from 'redux'
const Footer=(props)=>{
    return(
    		<footer>
	    		{/*<div className="container d-flex justify-content-between align-items-center">
		        	<p>Copyright © 2021 IDC. All rights reserved</p>
		        	<p>IDC <Link to="/">Privacy Policy</Link> I <Link to="/">Terms of Use</Link>.</p>
		        </div>*/}	
		        <div className="container">
				<label>Note: IDC and {(Object.values(props.urlData).length) > 0 ? props.urlData.sponsor_name : "your sponsor"} respect your right to privacy and will not reuse or resell your individual information and responses to any third party<br /> under any circumstances. <a style={{ textDecoration: "underline" }} href="javascript:void(0)" onClick={() => props.toggleModal(true)}>Read More</a></label>
                    <span>Copyright © 2021 IDC. All rights reserved. <a target="_blank" href="https://www.idc.com/about/privacy">Privacy Policy</a> | <a target="_blank" href="https://www.idc.com/ap/about-idc/terms-of-use">Terms & Conditions</a></span>
                </div>        
	    	</footer>
    	)
}
const mapStateToProps=(state)=>{
	return { urlData: state.urlData}
}
const mapDispatchToProps=(dispatch)=>{
	return bindActionCreators({ toggleModal: toggleModal },dispatch)
}
export default connect(mapStateToProps,mapDispatchToProps)(Footer)