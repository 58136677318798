import logo from './logo.svg';
import React,{useEffect}from 'react'
import './App.css';
import 'antd/dist/antd.css';
import HomeMain from './Home/container/main'
import { HashRouter as Router, Route } from 'react-router-dom';
import { Layout, notification } from 'antd';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';


/*------containers-------*/
import Organization from './Organization/container/main'
import People from './People/container/main'
import Process from './Process/container/main'
import Technology from './Technology/container/main'
import UseCase from './Usecase/container/main'
import Data from './Data/container/main'
/*------containers-------*/
/*------components-------*/
import Login from './Home/components/login'
import ForgotPassword from './Home/components/forgotPassword'
import HowItWorks from './Home/components/howItWorks'
import Registration from './Home/components/registration'
import OrgResult from './Organization/components/result'
import ProcessResult from './Process/components/result'
import PeopleResult from './People/components/result'
import TechnologyResult from './Technology/components/result'
import UseCaseResult from './Usecase/components/result'
import DataResult from './Data/components/result'
import ResetPassword from './Home/components/resetPassword'
import FooterDetail from './Footer/footerDetail'
/*------components-------*/
import SectionHeader from './Header/sectionHeader'
import Dashboard from './Dashboard/main'
import FinalResult from './FinalResult/main'
import Sponsor from './Sponsor/main'
import HomeFooter from './Footer/homeFooter';
import Footer from './Footer/main';
import Result from './Organization/components/result';
import './stylesheets/style.css';
import './stylesheets/animate.css';
import IdleTimerContainer from './idleTimerContainer'
/*------actions-------*/
import { getUrlData} from './Actions/getUrlData'
import {getAuthentication} from './Actions/loginCheck'
/*------actions-------*/
import Test from './Test'

const { Content } = Layout;
const App=(props)=>{
  useEffect(() => {
    props.getAuthentication()
    let url = new URL(window.location.href);
    let loc = url.pathname.replace(/\//g, "")
    if (url.pathname != "/" && loc != "") {
     props.getUrlData(url.pathname.replace(/\//g, ""))
    }
},[])
  let orgDisabled, result, peopleDisabled, processDisabled, technologyDisabled, useCaseDisabled, dataDisabled;
  if (props.auth.loaded == true && props.auth.authStatus == true){
     result = props.auth.surveyUpdates
     orgDisabled = result.length > 0 ? result.find(rec => rec.category == "Organization Dimension") : undefined
     peopleDisabled = result.length > 0 ? result.find(rec => rec.category == "People Dimension") : undefined
     processDisabled = result.length > 0 ? result.find(rec => rec.category == "Process Dimension") : undefined
     technologyDisabled = result.length > 0 ? result.find(rec => rec.category == "Technology Dimension") : undefined
     useCaseDisabled = result.length > 0 ? result.find(rec => rec.category == "Use Case Dimension") : undefined
     dataDisabled = result.length > 0 ? result.find(rec => rec.category == "Data Dimension") : undefined
  }
  let beforeLoginRoutes = (
    <div>
      <Route exact path="/" component={HomeMain} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/howItWorks" component={HowItWorks} />
      <Route exact path="/registration" component={Registration} />
      <Route exact path="/password-reset/:id" component={ResetPassword} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/result" component={Result} />
      <Route exact path="/sponsor" render={() =>
        <div>
          {props.auth.authStatus == true && props.auth.loaded == true && (<Sponsor />)}
        </div>
      } />
    </div>
  )
  let afterLoginRoutes;
  let sponsorRoute = (
    <div>
      <Route exact path="/" component={HomeMain} />
      <Route exact path="/sponsor" component={Sponsor} />
      <Route exact path="/howItWorks" component={HowItWorks} />
      <Route exact path="/password-reset/:id" component={ResetPassword} />
    
    </div>
  )
  if (props.auth.authData) {
    if (props.auth.authData.sponsorId) {
      let expirationDate = new Date(props.auth.authData.sponsorLockSixMonthExpiry)
      let localDate = new Date()
      if (localDate > expirationDate) {
        afterLoginRoutes = sponsorRoute
      } else {
        afterLoginRoutes = (
          <div>

            <Route exact path="/" component={HomeMain} />
            <Route exact path="/Test" component={Test}/>
            <Route exact path="/password-reset/:id" component={ResetPassword} />
            <Route exact path="/sectionHeader" component={SectionHeader} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/howItWorks" component={HowItWorks} />
            <Route exact path="/organization" render={() => <div>{(result.length&&!orgDisabled.isDisabled) && <Organization />}</div>} />
            <Route exact path="/organization-result" render={() => <div>{(props.auth.authData.orgResult!=undefined) && <OrgResult />}</div>} />
            <Route exact path="/people" render={() => <div>{(result.length && !peopleDisabled.isDisabled) && <People />}</div>} />
            <Route exact path="/people-result" render={() => <div>{(props.auth.authData.peopleResult != undefined) && <PeopleResult />}</div>} />
            <Route exact path="/process" render={() => <div>{(result.length && !processDisabled.isDisabled) && <Process />}</div>} />
            <Route exact path="/process-result" render={() => <div>{(props.auth.authData.peopleResult != undefined) && <ProcessResult />}</div>} />
            <Route exact path="/technology" render={() => <div>{(result.length && !technologyDisabled.isDisabled) && <Technology />}</div>} />
            <Route exact path="/technology-result" render={() => <div>{(props.auth.authData.technologyResult != undefined) && <TechnologyResult />}</div>} />
            <Route exact path="/use-case" render={() => <div>{(result.length && !useCaseDisabled.isDisabled) && <UseCase />}</div>} />
            <Route exact path="/use-case-result" render={() => <div>{(props.auth.authData.useCaseResult != undefined) && <UseCaseResult />}</div>} />
            <Route exact path="/data" render={() => <div>{(result.length && !dataDisabled.isDisabled) && <Data />}</div>} />
            <Route exact path="/data-result" render={() => <div>{(props.auth.authData.dataResult != undefined) && <DataResult />}</div>} />
            <Route exact path="/final-result" component={FinalResult} />
           
          </div>
        )
      }
    }else {
    afterLoginRoutes = sponsorRoute
  }
}
    var route = ''
    if (props.auth.loaded == true && props.auth.authStatus == false) {
      route = beforeLoginRoutes
    } else if (props.auth.loaded == true && props.auth.authStatus == true) {
      route = afterLoginRoutes
    } else {}
  

  return (
    <div>
    
      <Router>
        {/* {(props.auth.authStatus == true && props.auth.loaded == true)&&(
          <IdleTimerContainer>   </IdleTimerContainer>
        )} */}
        <div>
              <Layout className="site-layout">
                  <Content className="site-wrap">
                    {route}
                  <ToastContainer autoClose={3000}/>
                  </Content>
                  <Footer />
              </Layout>
        </div>
         <FooterDetail/>
      
        </Router>
     
     
    </div>
  );
}
const mapStateToProps=(state)=>{
  return{auth:state.auth}
}
const mapDispatchToProps=(dispatch)=>{
  return bindActionCreators({ getAuthentication: getAuthentication, getUrlData: getUrlData }, dispatch)
}
export default connect(mapStateToProps,mapDispatchToProps)(App);
