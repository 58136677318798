const initialState={
    data:{}
}
export default function (state=initialState,action) {
    switch (action.type) {
        case 'GET_QUESTION_BY_CATEGORY':
            return{...state,
                data:action.payload
            }
        default:return state
    }
}