import React from 'react'
import MainHeader from '../../Header/main'
import SectionHeader from '../../Header/sectionHeader'
import Footer from '../../Footer/main';
import { Button} from 'antd'
import { connect } from 'react-redux';
const HowItWorks=(props)=>{
    return(
		<React.Fragment>
		<MainHeader/>
         <div className="content-wrap HowItWorks-wrap" style={{backgroundColor:'#fff'}}>
            <div className="container">
            	<div className="howItWorks-wrap">
	            	<h4>About This Assessment:</h4>
					<p className="mb-0">The Data, Analytics and AI (DAAI) Readiness assessment is divided into six dimensions, with each dimension looking in depth at one capability that affects data and AI maturity.</p>
	            	<div className="six-section">
	            		<h4>The six dimensions are:</h4>
	            		<div className="row">
	            			<div className="col-6 col-md-4 col-lg-2 col-box">
	            				<div className="single-section color-orgz">
	            					<img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-organization.svg" className="img-fluid"/>
	            					<label>Organization</label>
	            				</div>
	            			</div>
	            			<div className="col-6 col-md-4 col-lg-2 col-box">
	            				<div className="single-section color-people">
									<img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-people.svg" className="img-fluid"/>
	            					<label>People</label>
	            				</div>
	            			</div>
	            			<div className="col-6 col-md-4 col-lg-2 col-box">
	            				<div className="single-section color-process">
									<img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-process.svg" className="img-fluid"/>
	            					<label>Process</label>
	            				</div>
	            			</div>
	            			<div className="col-6 col-md-4 col-lg-2 col-box">
	            				<div className="single-section color-tech">
									<img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-technology.svg" className="img-fluid"/>
	            					<label>Technology</label>
	            				</div>
	            			</div>
	            			<div className="col-6 col-md-4 col-lg-2 col-box">
	            				<div className="single-section color-data">
									<img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-data.svg" className="img-fluid"/>
	            					<label>Data</label>
	            				</div>
	            			</div>
	            			<div className="col-6 col-md-4 col-lg-2 col-box">
	            				<div className="single-section color-usecase">
									<img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-use-case.svg" className="img-fluid"/>
	            					<label>Use Case</label>
	            				</div>
	            			</div>
	            		</div>
							<p>Note that while there are six dimensions in total, the actual number of dimensions a respondent needs to complete might vary.</p>
							<p>Some dimensions – Process, Technology and Use Case – will be locked from view depending on how the very first question about the scope of data initiatives is answered.</p>
	            	</div>
	            	<div className="result-criteria">
	            		<h4>Your score will be based on the results provided: </h4>
	            		<ul>
								<li><img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/bullet-point.svg" className="img-fluid" />Organization and People are compulsory sections and can provide a viewpoint of your overarching strategy maturity.</li>
								<li><img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/bullet-point.svg" className="img-fluid" />Process, Technology and Data dimensions are quite specific and will offer insights on your relative performance for each capability.</li>
								<li><img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/bullet-point.svg" className="img-fluid" />The last dimension seeks to identify the use cases you have implemented and makes up the overall assessment.</li>
								<li><img src="https://daaistore.s3-ap-southeast-1.amazonaws.com/bullet-point.svg" className="img-fluid" />You will receive an Essential Guidance report with a maturity score and actionable insights based on completed applicable dimensions.</li>
	            		</ul>
	            	</div>
					{((Object.values(props.urlData).length)>0&&props.urlData.sponsor_name=="MDEC")&&(
							<div>
								<Button className="dimension-download-btn"><a  download href="https://daaistore.s3-ap-southeast-1.amazonaws.com/DAAI_Information_Guide.pdf">Download User Information Guide</a></Button>
							</div>
					)}
            	</div>
            </div>
         </div>
		</React.Fragment>
	)
}
const mapStateToProps=(state)=>{
	return {urlData: state.urlData}
}
export default connect(mapStateToProps)(HowItWorks)