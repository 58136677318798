import React,{Component} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import toggleModal from '../Actions/togglePop'
import {Modal} from 'antd'
const FooterDetail=(props)=>{
        return(
            <Modal
                title=""
                visible={props.status}
                footer={null}
                maskClosable={false}
                onCancel={() =>props.toggleModal(false) }
            >
                <p className="disclaimer-note">
                    <p>
                        IDC and {(Object.values(props.urlData).length) > 0 ? props.urlData.sponsor_name :"your sponsor"} respect your right to privacy and will not reuse or resell your individual information and responses to any third party under any circumstances.
                    </p>
                    <p>
                        Any and all information gathered will only be shared with your sponsor and for the purpose of this assessment and creating your essential guidance reports.
                    </p>
                    {((Object.values(props.urlData).length) > 0 && (props.urlData.privacyStatement))?(
                        <div dangerouslySetInnerHTML={{__html:props.urlData.privacyStatement}}/>
                    ):(
                        <p>
                            You are invited to participate in this survey based on the invitation from your sponsor who is the controller of your personal data. IDC acts as the personal data processor for your sponsor. The processing of your personal data is governed by the privacy policy of the respective sponsor.
                        </p>
                    )}
                   
                    {/* <p>
                        For a detailed explanation of IDC’s personal and corporate data privacy policies, please refer to these links - <a target="_blank" href="https://www.idc.com/about/privacy">IDC Privacy Policy</a> and <a target="_blank" href="https://www.idc.com/ap/about-idc/terms-of-use">Terms of Use</a>.
                    </p> */}
                </p>

            </Modal>
        )
}
function  mapStateToProps(state) {
    return {
        status:state.toggleModal.status,
        urlData: state.urlData
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ toggleModal: toggleModal }, dispatch)
}
export default connect(mapStateToProps, matchDispatchToProps) (FooterDetail)