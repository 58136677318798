import axios from 'axios';
import  apiURL  from '../env'
import { questStartRequest, questEndRequest, questErrorRequest } from './questionLoader';
export function getQuestionById(catId, surveyId) {
    var structureArr = {};
    return dispatch => {
        dispatch(questStartRequest())
        axios.get(apiURL+'questionCategory/getQuestionAnswerById?questionCategoryId=' + catId + '&access_token=' + localStorage.token)
            .then(function (response) {
                response.data.question.forEach((data, index) => {
                    let ansData = {}
                    data.answer.forEach((ans, ind) => {
                        if (ans.specify != undefined) {
                            ansData[ans.id] = { "answerId": ans.id, "answer": ans.answer, "specify": ans.specify }
                        } else {
                            ansData[ans.id] = { "answerId": ans.id, "answer": ans.answer}
                        }
                        (ans.tooltipText)&&(
                            ansData[ans.id].tooltipText = ans.tooltipText
                        )
                        ans.removeOther&&(
                            ansData[ans.id].removeOther = ans.removeOther
                        ) 
                    })
                    Object.assign(structureArr,{
                        [data.id]: {
                            "questionId": data.id, "tooltipText": data.tooltipText, "isLastQuestion":data.isLastQuestion,"showAnswer":data.showAnswer,"hideAnswer":data.hideAnswer,"question": data.question, "questionType": data.questionType,"questionDescription":data.questionDescription, "answers": ansData, "answerArray": [], "order": data.order,
                            "questionCategoryId": data.questionCategoryId, "questionCategoryName": response.data.que_cat_name, "dbAnswer": [], "minChoice": data.minChoice, "maxChoice": data.maxChoice,"questionHeader":data.sectionTitle,
                            "reportDependancy": data.reportDependancy, "reportKeyword": data.reportKeyword,"custom":data.custom
                        }
                    })
                })
                axios.get(apiURL + 'survey/getSurveyResponses?questionCategoryId=' + catId+'&access_token=' + localStorage.token).then((res) => {
                    res.data.forEach((value, index) => {
                                if(Object.values(structureArr)[index]!=undefined){
                                    structureArr[value.question._id].answerArray = value.answerArray
                                    structureArr[value.question._id].dbAnswer = value.answers

                                    // Object.assign(Object.values(structureArr)[index].answerArray, value.answerArray)
                                    // Object.assign(Object.values(structureArr)[index].dbAnswer, value.answers)
                                }
                        })
                        dispatch({
                            type: "GET_QUESTION_BY_CATEGORY",
                            payload: structureArr
                        })
                        dispatch(questEndRequest())
                    })
             
            }).catch(function response(error) {
                console.log(error)
            })

    }
}