import axios from 'axios'
import apiURL from '../env'
import * as type from '../Actions/constant'
import { startUserRequest, endUserRequest, endUserRequestError} from './getUserDataLoader'
export function getUserSectionData() {
    return dispatch => {
        dispatch(startUserRequest())
        axios.get(apiURL+'surveyUpdates/getSurveyUpdates').then((res) => {
            dispatch({
                type: type.GET_USER_DATA,
                payload: res.data
            })
        }).then(function (res) {
            dispatch(endUserRequest())
        }).catch(function (error) {
            dispatch(endUserRequestError)
        })
    }
}

