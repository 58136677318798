import React, { useEffect, useRef,useState} from 'react'
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import axios from 'axios'
import { toast } from 'react-toastify'
import {Redirect} from 'react-router-dom'
import { Link} from 'react-router-dom'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import apiURL from '../../env'
import MainHeader from '../../Header/main'
import domainArray from '../../domainArray'
import { getAuthentication } from '../../Actions/loginCheck'
const Registration=(props)=>{
    const { control, register, handleSubmit, watch, errors, getValues } = useForm();
    const password = useRef({});
    password.current = watch("password", "");
    const job = useRef({});
    job.current = watch("job", "");
    const headquarter = useRef({});
    headquarter.current = watch("headquarter", "");
    const industry = useRef({});
    industry.current = watch("industry", "");
    const knowAssessment = useRef({});
    knowAssessment.current = watch("knowAssessment", "");
    const [domainMsg, setDomainMsg] = useState("")
    const [path, setPath] = useState("")
    var firstStatement, secondStatement, finalStatement,urlStatement;
        if ((Object.values(props.urlData).length) > 0 && props.urlData.consentStatement != "") {
            firstStatement = props.urlData.consentStatement.split(" at ")[0]
            secondStatement = props.urlData.consentStatement.split(" at ")[1]
            finalStatement = secondStatement.split(" and ")[1]
            urlStatement = secondStatement.split(" and ")[0]
        }

    const onSubmit = signUpData => {
        if (signUpData.email.trim() != "") {
            let domainPart = signUpData.email.toLowerCase().split("@")[1]
            if (domainArray.includes(domainPart)) {
                setDomainMsg("Please provide a valid business email address (webmail services are not accepted).")
            } else {
                setDomainMsg("")
                if (signUpData) {
                    let data = {
                        "first_name": signUpData.firstName,
                        "last_name": signUpData.lastName,
                        "email": signUpData.email,
                        "company_name": signUpData.companyName,
                        "job": signUpData.job.value,
                        "otherjob": signUpData.otherjob,
                        "headquarter": signUpData.headquarter.value,
                        "otherHeadquarter": signUpData.otherHeadquarter,
                        "industry": signUpData.industry.value,
                        "otherIndustry": signUpData.otherIndustry,
                        "employees": signUpData.employees.value,
                        "strategy": signUpData.strategy.value,
                        "orgType": signUpData.orgType.value,
                        "knowAssessment": signUpData.knowAssessment.value,
                        "otherKnowAbout": signUpData.otherKnowAbout,
                        // "staff":signUpData.staff.value,
                        // "professional": signUpData.professional.value,
                        "password": signUpData.password,
                        "realm": "user"
                    }
                    if (signUpData.malaysiaState) {
                        data.malaysiaState = signUpData.malaysiaState.value
                    }
                    axios({
                        method: 'POST',
                        url: apiURL +'leads/registerLeads',
                        data: data

                    }).then((res) => {
                        toast.success("Registration successful. Please check your registered email", {
                            position: "top-right"
                        });
                        localStorage.token = res.data.token.id
                        props.getAuthentication()
                        setPath("/sponsor")
                    }).catch((err) => {
                        if (err.response.data.error.details.messages.email) {
                            toast.error("Email already registered", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                    })
                }
            }
        }
    }
    const knowAboutAssessment=[
        { value: 'Event', label: 'Event' },
        { value: 'Marketing', label: 'Marketing' },
        { value: 'Media / lead gen', label: 'Media / lead gen' },
        { value: 'Sales representative', label: 'Sales representative' },
        { value: 'Social Media', label: 'Social Media' },
        { value: 'Web', label: 'Web' },
        { value: 'Others [please specify]', label: 'Others [please specify]' }
    ]
    const jobOptions = [
        { value: 'Chairman / Board of directors', label: 'Chairman / Board of directors' },
        { value: 'Chief Administrative Officer, or Chief Operation Officer', label: 'Chief Administrative Officer, or Chief Operation Officer'  },
        { value: 'Chief Analytics Officer', label: 'Chief Analytics Officer'  },
        { value: 'Chief Data Governance Officer, or Chief Data Protection Officer', label: 'Chief Data Governance Officer, or Chief Data Protection Officer' },
        { value: 'Chief Data Officer', label: 'Chief Data Officer' },
        { value: 'Chief Digital Officer', label: 'Chief Digital Officer' },
        { value: 'Chief (Digital) Transformation Officer', label: 'Chief (Digital) Transformation Officer' },
        { value: 'Chief Executive Officer', label: 'Chief Executive Officer' },
        { value: 'Chief Financial Officer', label: 'Chief Financial Officer' },
        { value: 'Chief Human Resource Officer', label: 'Chief Human Resource Officer' },
        { value: 'Chief Information Officer', label: 'Chief Information Officer' },
        { value: 'Chief Information Security Officer', label: 'Chief Information Security Officer' },
        { value: 'Chief Technology Officer', label: 'Chief Technology Officer' },
        { value: 'Managing Director', label: 'Managing Director' },
        { value: 'Head (Chief, Director) of AI, or Analytics, or Data Science or Data Engineering', label: 'Head (Chief, Director) of AI, or Analytics, or Data Science or Data Engineering' },
        { value: 'Head (Director) of Business Function', label: 'Head (Director) of Business Function' },
        { value: 'Head (EVP, VP) of AI or Analytics, or Data Science or Data Engineering', label: 'Head (EVP, VP) of AI or Analytics, or Data Science or Data Engineering' },
        { value: 'Head (EVP, VP) of Business Function', label: 'Head (EVP, VP) of Business Function' },
        { value: 'Head (Director) of IT', label: 'Head (Director) of IT' },
        { value: 'Others (please specify)', label: 'Others (please specify)' },
    ];
    const headquarterOption = [
        { value: 'Australia', label: 'Australia' },
        { value: 'Hong Kong', label: 'Hong Kong' },
        { value: 'Indonesia', label: 'Indonesia' },
        { value: 'Japan', label: 'Japan' },
        { value: 'Korea', label: 'Korea' },
        { value: 'Malaysia', label: 'Malaysia' },
        { value: 'New Zealand', label: 'New Zealand' },
        { value: 'Singapore', label: 'Singapore' },
        { value: 'Thailand', label: 'Thailand' },
        { value: 'Others (please specify)', label: 'Others (please specify)' },
    ];
    const malaysiaOption=[
        { value: 'Federal Territory of Kuala Lumpur', label: 'Federal Territory of Kuala Lumpur' },
        { value: 'Federal Territory of Labuan', label: 'Federal Territory of Labuan' },
        { value: 'Federal Territory of Putrajaya', label: 'Federal Territory of Putrajaya' },
        { value: 'Johor', label: 'Johor' },
        { value: 'Kedah', label: 'Kedah' },
        { value: 'Kelantan', label: 'Kelantan' },
        { value: 'Malacca', label: 'Malacca' },
        { value: 'Negeri Sembilan', label: 'Negeri Sembilan' },
        { value: 'Pahang', label: 'Pahang' },
        { value: 'Perak', label: 'Perak' },
        { value: 'Perlis', label: 'Perlis' },
        { value: 'Penang', label: 'Penang' },
        { value: 'Sabah', label: 'Sabah' },
        { value: 'Sarawak', label: 'Sarawak' },
        { value: 'Selangor', label: 'Selangor' },
        { value: 'Terengganu', label: 'Terengganu' },
    ]
    const industryOption=[
        { value: "Agriculture (Primary commodities such as Palm Oil, Rubber)", label: "Agriculture (Primary commodities such as Palm Oil, Rubber)" },
        { value: "Banking", label:"Banking"},
        { value: "Communications & Media", label: "Communications & Media" },
        { value: "Education", label: "Education" },
        { value: "Engineering & Construction", label: "Engineering & Construction" },
        { value: "Financial Markets", label: "Financial Markets" },
        { value: "Food and Beverage", label: "Food and Beverage" },
        { value: "Government", label: "Government" },
        { value: "Healthcare", label: "Healthcare" },
        { value: "Insurance", label: "Insurance" },
        { value: "Manufacturing (Discrete)", label: "Manufacturing (Discrete)" },
        { value: "Manufacturing (Process)", label: "Manufacturing (Process)" },
        { value: "Professional Services", label: "Professional Services" },
        { value: "Resources Industries (Oil & Gas, Mining)", label: "Resources Industries (Oil & Gas, Mining)" },
        { value: "Retail", label: "Retail" },
        { value: "Transportation & Transportation Services", label: "Transportation & Transportation Services" },
        { value: "Utilities", label: "Utilities" },
        { value: "Wholesale", label: "Wholesale" },
        { value: "Others (please specify)", label: "Others (please specify)" },
    ]
    const employeesOption=[
        { value: "<200", label: "<200" },
        { value: "200 - 499", label: "200 - 499" },
        { value: "500 - 999", label: "500 - 999" },
        { value: "1,000 - 2,499", label: "1,000 - 2,499" },
        { value: "2,500 - 4,999", label: "2,500 - 4,999" },
        { value: "5,000 - 9,999", label: "5,000 - 9,999" },
        { value: "10,000+", label: "10,000+" },
    ]
    const strategyOption = [
        { value: "I am a decision maker of data/analytics/AI strategy", label: "I am a decision maker of data/analytics/AI strategy" },
        { value: "I am a key influencer of data/analytics/AI strategy", label: "I am a key influencer of data/analytics/AI strategy" },
        { value: "I am a key stakeholder/sponsor of data/analytics/AI strategy", label: "I am a key stakeholder/sponsor of data/analytics/AI strategy" },
        { value: "I am responsible for the execution of data/analytics/AI strategy", label: "I am responsible for the execution of data/analytics/AI strategy" },
        { value: "I am interested/looking into data/analytics/AI strategy", label: "I am interested/looking into data/analytics/AI strategy" },
        { value: "None of the above", label: "None of the above" },
    ]
    const orgTypeOption = [
        { value: "Multinational corporation ", label: "Multinational corporation " },
        { value: "Private company ", label: "Private company " },
        { value: "Public-listed company ", label: "Public-listed company " },
        { value: "State-owned enterprise", label: "State-owned enterprise" },
    ]
    // const staffOption = [
    //     { value: "<5", label: "<5" },
    //     { value: "5 - 10", label: "5 - 10" },
    //     { value: "10 - 20", label: "10 - 20" },
    //     { value: "20 - 50", label: "20 - 50" },
    //     { value: ">50", label: ">50" },
    // ]
    // const profOption = [
    //     { value: '<5', label: '<5' },
    //     { value: '5 - 10', label: '5 - 10' },
    //     { value: '10 - 20', label: '10 - 20' },
    //     { value: '20 - 50', label: '20 - 50' },
    //     { value: '>50', label: '>50' },
    // ]
    const getFocus=(id)=>{
        var elmnt = document.getElementById(id);
        elmnt.scrollIntoView();
    }
    return (
        <React.Fragment>
        <MainHeader />
            <div className="login-wrap content-wrap registeration-wrap"  style={{ backgroundImage: "url('https://daaistore.s3-ap-southeast-1.amazonaws.com/bg-login.jpg')"}}>
            <div className="container">
            
            <div className="login-form registeration-form">
             <h3>Register Here</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="form-group col-md-6">
                        <input name="firstName" autoFocus placeholder="First Name" className="form-control" ref={register({ required: 'First name is required' })} />
                        {errors.firstName && <span className="login-error-msg">{errors.firstName.message}</span>}
                    </div>
                    <div className="form-group col-md-6">
                        <input name="lastName" placeholder="Last Name" className="form-control" ref={register({ required: 'Last name is required' })} />
                        {errors.lastName && <span className="login-error-msg">{errors.lastName.message}</span>}
                    </div>
                    <div className="form-group col-md-6">
                        <input name="email" placeholder="Business Email" className="form-control" ref={register({
                                pattern: {
                                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Invalid E-mail address' 
                                },
                                required: 'Email is required'
                            })} />
                        {errors.email && <span className="login-error-msg">{errors.email.message}</span>}
                        <span className="login-error-msg">{domainMsg}</span>
                    </div>
                    <div className="form-group col-md-6">
                        <input name="companyName" placeholder="Company Name" className="form-control" ref={register({ required: 'Company name is required' })} />
                        {errors.companyName && <span className="login-error-msg">{errors.companyName.message}</span>}
                    </div>

                    <div className="form-group col-md-12" id="jobId">
                        <span className="question-label">Job title/function</span> 
                        <Controller
                            render={(props) => (
                                <Select onFocus={() => getFocus("jobId")} isSearchable={true} options={jobOptions} value={props.value}  placeholder="Choose One"
                                    onChange={props.onChange} isClearable={true}/>
                            )}
                            name="job"
                            control={control}
                            rules={{ required: 'Job title/function is required' }}
                        />
                        {errors.job && <span className="login-error-msg">{errors.job.message}</span>}
                            {job.current && job.current.value =="Others (please specify)"&&(
                            <React.Fragment>
                            <input name="otherjob" className="form-control other-form-field" placeholder="Other Job title/function" ref={register({ required: 'Other Job is required' })} />
                                {errors.otherjob && <span className="login-error-msg">{errors.otherjob.message}</span>}
                            </React.Fragment>
                        )}
                    </div>
                    <div className="form-group  col-md-12" id="headId">
                        <span className="question-label">Headquarter Location</span>
                        <Controller
                            render={(props) => (
                                <Select onFocus={() => getFocus("headId")} isSearchable={true} options={headquarterOption} value={props.value} placeholder="Choose One"
                                    onChange={props.onChange} isClearable={true} />
                            )}
                            name="headquarter"
                            control={control}
                            rules={{ required: 'Headquarter location is required' }}
                        />
                        {errors.headquarter && <span className="login-error-msg">{errors.headquarter.message}</span>}
                            {headquarter.current && headquarter.current.value =="Others (please specify)"&&(
                            <React.Fragment>
                                <input name="otherHeadquarter" className="form-control other-form-field" placeholder="Other Headquarter Location" ref={register({ required: 'Other Headquarter location is required' })} />
                                {errors.otherHeadquarter && <span className="login-error-msg">{errors.otherHeadquarter.message}</span>}
                            </React.Fragment>
                        )}
                    </div>
                    {headquarter.current && headquarter.current.value == "Malaysia" && (
                        <div className="form-group col-md-12" id="malaysiaId">
                                <React.Fragment>
                                <span className="question-label">State</span>
                                    <Controller
                                        render={(props) => (
                                            <Select onFocus={() => getFocus("malaysiaId")} isSearchable={true} options={malaysiaOption} value={props.value} placeholder="Choose One"
                                                onChange={props.onChange} isClearable={true} />
                                        )}
                                        name="malaysiaState"
                                        control={control}
                                        rules={{ required: 'Malaysia state is required' }}
                                    />
                                    {errors.malaysiaState && <span className="login-error-msg">{errors.malaysiaState.message}</span>}
                                </React.Fragment>
                        </div>
                    )}
                    <div className="form-group col-md-12" id="primaryId">
                        <span className="question-label">Primary industry</span>
                        <Controller
                            render={(props) => (
                                <Select onFocus={() => getFocus("primaryId")} isSearchable={true} options={industryOption} value={props.value} placeholder="Choose One"
                                    onChange={props.onChange} isClearable={true} />
                            )}
                            name="industry"
                            control={control}
                            rules={{ required: 'Primary industry is required' }}
                        />
                        {errors.industry && <span className="login-error-msg">{errors.industry.message}</span>}
                            {industry.current && industry.current.value == "Others (please specify)" && (
                            <React.Fragment>
                                <input name="otherIndustry"  className="form-control other-form-field" placeholder="Other primary industry" ref={register({ required: 'Other primary industry is required' })} />
                                {errors.otherIndustry && <span className="login-error-msg">{errors.otherIndustry.message}</span>}
                            </React.Fragment>
                        )}
                    </div>

                    <div className="form-group col-md-12" id="employeeId">
                        <span className="question-label">Approximately how many employees are there in your organization</span>
                        <Controller
                            render={(props) => (
                                <Select onFocus={() => getFocus("employeeId")} isSearchable={true} options={employeesOption} value={props.value} placeholder="Choose One"
                                    onChange={props.onChange} isClearable={true} />
                            )}
                            name="employees"
                            control={control}
                            rules={{ required: 'Employee is required' }}
                        />
                        {errors.employees && <span className="login-error-msg">{errors.employees.message}</span>}
                    </div>
                    <div className="form-group col-md-12" id="strategyId">
                        <span className="question-label">How is your responsibility related to your organization's data / analytics / AI strategy?</span>
                            <Controller
                                render={(props) => (
                                            <Select onFocus={() => getFocus("strategyId")}  isSearchable={true} options={strategyOption} value={props.value} placeholder="Choose One"
                                        onChange={props.onChange} isClearable={true} />
                                )}
                                name="strategy"
                                control={control}
                                rules={{ required: 'This field is required' }}
                            />
                            {errors.strategy && <span className="login-error-msg">{errors.strategy.message}</span>}
                    </div>

                    <div className="form-group col-md-12" id="typeId">
                        <span className="question-label">Which of the following best describes the type of your organization?</span>
                        <Controller
                            render={(props) => (
                                        <Select onFocus={() => getFocus("typeId")}  isSearchable={true} options={orgTypeOption} value={props.value} placeholder="Choose One"
                                    onChange={props.onChange} isClearable={true} />
                            )}
                            name="orgType"
                            control={control}
                            rules={{ required: 'This field is required' }}
                        />
                        {errors.orgType && <span className="login-error-msg">{errors.orgType.message}</span>}
                    </div>
                    <div className="form-group col-md-12" id="knowId">
                            <span className="question-label">How did you come to know about this assessment?</span>
                            <Controller
                                render={(props) => (
                                    <Select onFocus={() => getFocus("knowId")} isSearchable={true} options={knowAboutAssessment} value={props.value} placeholder="Choose One"
                                    onChange={props.onChange} isClearable={true} />
                                )}
                                name="knowAssessment"
                                control={control}
                                rules={{ required: 'This field is required' }}
                            />
                            {errors.knowAssessment && <span className="login-error-msg">{errors.knowAssessment.message}</span>}
                            {knowAssessment.current && knowAssessment.current.value == "Others [please specify]" && (
                            <React.Fragment>
                                    <input name="otherKnowAbout" className="form-control other-form-field" placeholder="" ref={register({ required: 'This field is required' })} />
                                    {errors.otherKnowAbout && <span className="login-error-msg">{errors.otherKnowAbout.message}</span>}
                            </React.Fragment>
                            )}
                    </div>
                    {/* <div className="form-group col-md-12" id="staffId">
                        <span className="question-label">Approximately how many IT staff are there in your organization?</span>
                        {((Object.values(props.urlData).length) > 0 && props.urlData._id =="603de1c1daf4ed078a38d4fb")?(
                            <React.Fragment>
                               <Controller
                                        render={(props) => (
                                            <Select onFocus={() => getFocus("staffId")} isSearchable={true} options={staffOption} value={props.value} placeholder="Choose One"
                                                onChange={props.onChange} isClearable={true} />
                                        )}
                                        name="staff"
                                        control={control}
                                        rules={{ required: 'This field is required' }}
                                />
                                {errors.staff && <span className="login-error-msg">{errors.staff.message}</span>}
                            </React.Fragment>
                        ):(
                            <Controller
                                render={(props) => (
                                    <Select onFocus={() => getFocus("staffId")}isSearchable={true} options={staffOption} value={props.value} placeholder="Choose One"
                                    onChange={props.onChange} isClearable={true} />
                                )}
                                name="staff"
                                control={control}
                            />
                        )}
                    </div> */}

                    {/* <div className="form-group col-md-12" id="profId">
                        <span className="question-label">Approximately how many data professionals are there in your organization?</span>
                        <Controller
                            render={(props) => (
                                        <Select onFocus={() => getFocus("profId")} isSearchable={true} options={profOption} value={props.value} placeholder="Choose One"
                                    onChange={props.onChange} isClearable={true} />
                            )}
                            name="professional"
                            control={control}
                            rules={{ required: 'This field is required' }}
                        />
                        {errors.professional && <span className="login-error-msg">{errors.professional.message}</span>}
                    </div> */}
                    <div className="form-group col-md-6">
                        <input name="password" type="password" className="form-control" placeholder="Password" ref={register({
                            required: "Password is required",
                            pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                                message: "Invalid Password"
                            }
                        })} />
                                    <span className="login-error-msg" style={{ color: 'black' }}>{"Password needs to have at least 8 alphanumeric characters, with upper and lower case letters, and at least one of these special characters only (!@#$%^&*)."}</span>
                        {errors.password && <span className="login-error-msg">{errors.password.message}</span>}
                    </div>
                    <div className="form-group col-md-6">
                        <input name="confirmPassword" type="password" className="form-control" placeholder="Confirm Password" ref={register({
                            validate: value =>
                                value === password.current || "The passwords do not match",
                            required: 'Confirm Password is required' })}/>
                        {errors.confirmPassword && <span className="login-error-msg">{errors.confirmPassword.message}</span>}
                    </div>
                            <div className="form-group col-md-12">
                            {((Object.values(props.urlData).length) > 0 && props.urlData.consentStatement!="") ? (
                                <React.Fragment>
                                    <div className="mdec-terms">
                                        <input type="checkbox" name="terms" ref={register({ required: 'Please click to accept the terms of use' })} /> {firstStatement} at <a href={urlStatement} target="_blank" style={{ textDecoration: 'underline' }}>{urlStatement} </a>
                                            and {finalStatement}
                                    </div>
                                        {errors.terms && <span className="login-error-msg">{errors.terms.message}</span>}
                                </React.Fragment>
                            ):(
                                    <div className="form-group mdec-terms mb-0">
                                            <input type="checkbox" name="terms" ref={register({ required: 'Please click to accept the terms of use' })} /> By clicking Submit, you accept the <a href="https://www.idc.com/ap/about-idc/terms-of-use" target="_blank" style={{ textDecoration: 'underline' }}>Terms of Use.</a>
                                        {errors.terms && <span className="login-error-msg">{errors.terms.message}</span>}
                                    </div>
                            )}
                            </div>
                            <div className="col-md-12 text-center">
                                <button className="login-submit-btn btn w-100" type="submit">Submit</button>
                                <span className="signup-msg">Existing User? <Link to="/login">Click here</Link> to Log in</span>
                            </div>
                    </div>
                </form>
                </div>
            </div>
            {(path!="")&&<Redirect to={path} />}

        </div>
        </React.Fragment>
    )
}
const mapStateToProps=(state)=>{
    return { urlData: state.urlData}
}
const mapDispatchToProps=(dispatch)=>{
    return bindActionCreators({  getAuthentication: getAuthentication},dispatch)
}
export default connect(mapStateToProps,mapDispatchToProps)(Registration)