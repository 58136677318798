import * as type from './constant'
export function startUserRequest() {
    return {
        type: type.USER_REQUEST_START
    }
}
export function endUserRequest() {
    return {
        type: type.USER_REQUEST_END
    }
}

export function endUserRequestError() {
    return {
        type: type.USER_REQUEST_ERROR
    }
}