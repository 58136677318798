const initialState={
    loading:false
}
export default function(state=initialState,action){
    switch (action.type) {
        case "REQUEST_START": return Object.assign({}, state, {
            loading: true
        });
        case "REQUEST_END": return Object.assign({}, state, {
            loading: false
        });
        case "REQUEST_ERROR": return Object.assign({}, state, {
            loading: false
        });
        default:return state
    }
}