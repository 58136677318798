import React, { useState, useEffect} from "react"
import { Progress } from 'antd'
import { getUserSectionData } from '../Actions/getUserData'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
const SectionHeader=(props)=>{
	useEffect(() => {
		props.getUserSectionData()
	}, [])
	const [state, setstate] = useState({
		"Organization Dimension": {
			sectionName: "Organization",
			image: "https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-organization.svg",
			completedClass: "menu-item survey-completed color-orgz",
			inProgressClass: "menu-item survey-progress color-orgz",
			path: "organization"
		},
		"People Dimension": {
			sectionName: "People",
			image: "https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-people.svg",
			completedClass: "menu-item survey-completed color-people",
			inProgressClass: "menu-item survey-progress color-people"
		},
		"Process Dimension": {
			sectionName: "Process",
			image: "https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-process.svg",
			completedClass: "menu-item survey-completed color-process",
			inProgressClass: "menu-item survey-progress color-process"
		},
		"Technology Dimension": {
			sectionName: "Technology",
			image: "https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-technology.svg",
			completedClass: "menu-item survey-completed color-tech",
			inProgressClass: "menu-item survey-progress color-tech"
		},
		"Data Dimension": {
			sectionName: "Data",
			image: "https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-data.svg",
			completedClass: "menu-item survey-completed color-data",
			inProgressClass: "menu-item survey-progress color-data"
		},
		"Use Case Dimension": {
			sectionName: "Use Case",
			image: "https://daaistore.s3-ap-southeast-1.amazonaws.com/icon-home-use-case.svg",
			completedClass: "menu-item survey-completed color-usecase",
			inProgressClass: "menu-item survey-progress color-usecase"
		}
	})
	if (props.timeValue != undefined) {
		if (props.calledButton != "back") {
			var time = ((props.timeValue - props.currentValue + 1) * 30) / 60
		} else {
			var time = ((props.timeValue - props.lastIndex) * 30) / 60
		}
		var value = Math.floor(((props.currentValue - 0.5) / props.total) * 100)
	}
    return (
    	<div className="sectionHeader  animated slideInDown">
	    	<div className="container-fluid">
	        	<div className="row m-0">
					{!props.userLoader.loading&&props.userSectionData.data && props.userSectionData.data.map((rec,index) => {
						return (<div className="col-2 p-0">
							<div className={rec.isDisabled ? "menu-item " : ((rec.isCompleted) ? state[rec.categoryName].completedClass : state[rec.categoryName].inProgressClass)}>
								<div className="d-md-flex align-items-center justify-content-center">
									<img src={state[rec.categoryName].image} className="img-fluid menu-item-img" />
									<div className="survey-count">{index+1}</div>
									<label>{state[rec.categoryName].sectionName}</label>
								</div>
								{(!rec.isComplete && props.timeValue != undefined && state[rec.categoryName].sectionName==props.sectionName )&& (
								<div className="menu-progress">
									<Progress percent={value} status="active" strokeColor="#fff" trailColor="transparent" />
									<font>Expected Duration: {time} Min</font>
								</div>
								)}
							</div>
						</div>)
					})}
	        	</div>
	    	</div>
	    </div>
    )
}
const mapStateToProps = (state) => {

	return {
		authLoader: state.authLoader,
		auth: state.auth,
		userSectionData: state.userSectionData,
		userLoader: state.userLoader
	}
}
const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getUserSectionData: getUserSectionData }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(SectionHeader)