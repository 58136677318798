import * as type from './constant'
export function questStartRequest() {
    return {
        type: type.QUESTION_REQUEST_START
    }
}
export function questEndRequest() {
    return {
        type: type.QUESTION_REQUEST_END
    }
}

export function questErrorRequest() {
    return {
        type: type.QUESTION_REQUEST_ERROR
    }
}