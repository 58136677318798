const initialState = {
    status: false
}
export default function (state = initialState, action) {
    switch (action.type) {
        case "TOGGLE_MODAL":
            return {
                ...state,
                status: action.status
            }
        default:return state
    }
    return state
}